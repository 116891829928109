import { gql } from "@apollo/client";

export const BOOK_DATE_QUERY = gql`
  query bookDateQuery($id: ID!) {
    bgmConcept {
      offer(id: $id) {
        id
        therapyOffer {
          contactNumberRequired
        }
        eventDays {
          date
          events {
            id
            date
            guestState
            therapist {
              id
              displayName
              photo
            }
            room {
              id
              name
            }
            offers {
              guestState
              totalSlots
              freeSlots
              slots {
                timeStart
                timeEnd
                guestState
              }
              offer {
                id
                title
                emailNote
                contactNumberRequired
              }
              price {
                total
                companySubventionAmount
              }
            }
          }
        }
      }
    }
    guest {
      id
      guestSubventionLimit {
        id
        currentValue
        value
      }
    }
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            showPrices
            showSubventionValue
          }
        }
        ... on ModuleConfigPayment {
          enabledEventPaymentTypes
        }
        ... on ModuleConfigCalendar {
          dateEditableUntil
          courseCancelableUntil
        }
        ... on ModuleConfigCalendar {
          reservationEnabled
        }
      }
    }
  }
`;
export const BOOK_COURSE_QUERY = gql`
  query bookCourseQuery($id: ID!) {
    bgmConcept {
      offer(id: $id) {
        id
        courses {
          id
          day
          dateBegin
          dateEnd
          timeBegin
          timeEnd
          room {
            id
            name
            emailNote
          }
          events {
            id
            date
            guestState
            therapist {
              id
              photo
              displayName
            }
            offers {
              totalSlots
              freeSlots
            }
          }
          therapyOffer {
            id
            price {
              total
              companySubventionAmount
            }
          }
        }
      }
    }
    guest {
      id
      guestSubventionLimit {
        id
        currentValue
        value
      }
    }
    config {
      modules {
        ... on ModuleConfigFrontend {
          employee {
            showPrices
            showSubventionValue
          }
        }
        ... on ModuleConfigPayment {
          enabledEventPaymentTypes
        }
        ... on ModuleConfigCalendar {
          dateEditableUntil
          courseCancelableUntil
        }
        ... on ModuleConfigCalendar {
          reservationEnabled
        }
      }
    }
  }
`;
