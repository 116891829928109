export default {
  "app.account.accessData.password.error.400x06":
    "Das Passwort darf nicht den Benutzernamen enthalten. Bitte erstelle ein stärkeres Passwort.",
  "app.account.accessData.password.error.400x07":
    "Das Passwort darf nicht dreimal hintereinander dasselbe Zeichen enthalten. Bitte erstelle ein stärkeres Passwort.",
  "app.account.accessData.password.error.400x08":
    "Das Passwort ist entweder zu schwach oder bereits kompromittiert bzw. öffentlich bekannt. Bitte erstelle ein stärkeres Passwort.",
  "app.icon.title.date-edit": "Bestehenden Termin bearbeiten.",
  "app.icon.title.reservation-edit": "Bestehende Reservierung bearbeiten.",
  "app.icon.title.prescription-new": "Das Rezept liegt zur Prüfung vor.",
  "app.icon.title.prescription-download":
    "Die Quittung liegt zum Download vor.",
  "app.icon.title.prescription-checked": "Die Rezept wurde freigegeben.",
  "app.icon.title.prescription-rejected":
    "Das Rezept musste leider abgelehnt werden.",
  "app.icon.title.more": "Mehr anzeigen",
  "app.title": "BGMSuite Mitarbeiterportal",
  "app.global.language": "Sprache",
  "app.global.error": "Fehler",
  "app.modal.close": "schließen",
  "app.modal.confirm": "bestätigen",
  "app.modal.cancel": "abbrechen",
  "app.global.technicalError":
    "Leider ist ein Fehler aufgetreten. \\nBitte versuche es später noch einmal.",
  "app.error.lowbudget": "Leider ist dein aktuelles Budget nicht ausreichend.",
  "app.global.title": "Titel",
  "app.global.from": "von",
  "app.global.at": "am",
  "app.global.yes": "Ja",
  "app.global.no": "Nein",
  "app.global.with": "bei",
  "app.global.clock": "Uhr",
  "app.global.note": "Achtung",
  "app.global.priceUnit": " €",
  "app.global.loadMore": "mehr anzeigen",
  "app.global.noInternet.default":
    "Log-in is fehlgeschlagen. Bitte überprüfe deine Internetverbindung.",
  "app.navigation.more.title": "Mehr",
  "app.global.delete": "löschen",
  "app.logo.alt": "Gesundheitsbudget",
  "app.login.title": "Login",
  "app.login.login": "login",
  "app.login.username": "Benutzername",
  "app.login.password": "Passwort",
  "app.login.legal.title": "Alles, was Recht ist",
  "app.login.loginError.default": "Beim Login ist ein Fehler aufgetreten.",
  "app.login.loginError.401x01":
    "Zu viele Login-Versuche. Dein Zugang wurde für 10 Minuten gesperrt.",
  "app.login.loginError.401x02":
    "Dein Zugang wurde gesperrt. Bitte wende dich an email@gebu.bgm.services.",
  "app.login.loginError.401x03":
    "Dein Benutzername oder Passwort sind ungültig.",
  "app.login.loginError.401x04": "Der Zugang aus deinem Netzwerk ist gesperrt.",
  "app.login.loginErrorTooManyAttempts": "",
  "app.login.loginErrorEmptyUsername": "Bitte gib deinen Benutzernamen an.",
  "app.login.loginErrorEmptyPassword": "Bitte gib dein Passwort ein.",
  "app.account.title": "Meine Daten",
  "app.account.accessMessage": "Am: {datetime} mit der IP-Adresse: {ip}",
  "app.account.basicData": "Stammdaten",
  "app.account.basicData.error.default": "Ein Fehler ist aufgetreten.",
  "app.account.basicData.error.403": "Leider ist ein Fehler aufgetreten (403).",
  "app.account.basicData.error.404": "Leider ist ein Fehler aufgetreten (404).",
  "app.account.basicData.error.400x00": "Die Stammdaten sind ungültig.",
  "app.account.basicData.error.400x01": "Die Kontodaten sind ungültig.",
  "app.account.basicData.headline": "Kontaktdaten",
  "app.account.accounting": "Kontodaten",
  "app.account.accounting.sepaMandate": "Sepa Lastschrift Mandat",
  "app.account.accounting.sepaMandate.identifier":
    "Gläubigeridentifikationsnummer:",
  "app.account.accounting.sepaMandate.reference": "Mandatsreferenz:",
  "app.account.accounting.sepaMandate.referenceTodo":
    "wird mit Erteilung mitgeteilt",
  "app.account.accounting.sepaMandate.text":
    "Ich ermächtige die wellfeelin GmbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der wellfeelin GmbH auf mein Konto gezogenen Lastschriften einzulösen. Die Frist für die Vorabankündigung (Pre-Notification) wird auf 5 Tage verkürzt.",
  "app.account.accounting.sepaMandate.create": "Lastschriftmandat erteilen",
  "app.account.accounting.sepaMandate.cancel": "Lastschriftmandat widerrufen",
  "app.account.accounting.sepaMandate.canceled":
    "Das Lastschriftmandat wurde zum heutigen Tag widerrufen.",
  "app.account.sepaMandate.error.400x00":
    "Das Mandat wurde bereits widerrufen.",
  "app.account.sepaMandate.error.account":
    "Bitte verfollständigen Sie zunächst die Kontodaten.",
  "app.account.accounting.sepaMandate.doCancellations":
    "Rechnungen für kurzfristige Terminausfälle in Form von Ausfallgebühren sollen von meinem Konto eingezogen werden. ",
  "app.account.accounting.cancellationFees": "Ausfallgebühren",
  "app.account.accessData": "Zugangsdaten",
  "app.account.accessData.username.error.default":
    "Ein Fehler ist aufgetreten.",
  "app.account.accessData.username.error.400x03":
    "Der Benutzername darf nur aus Zahlen, Buchstaben und -_. bestehen.",
  "app.account.accessData.username.error.400x04":
    "Dieser Benutzername ist bereits vergeben.",
  "app.account.accessData.password.error.default":
    "Ein Fehler ist aufgetreten.",
  "app.account.accessData.password.error.400x01": "Bitte gib ein Passwort an.",
  "app.account.accessData.password.error.400x02":
    "Das Passwort genügt nicht den Anforderungen.",
  "app.account.accessData.password.error.400x12":
    "Dieses Passwort wurde bereits zuvor verwendet. Bitte erstelle ein neues Passwort.",
  "app.account.accessData.error": "Zugangsdaten",
  "app.account.emailSettings": "Benachrichtigungen",
  "app.account.emailSettings.error.default": "Ein Fehler ist aufgetreten.",
  "app.account.emailSettings.error.400": "Ungültiges Mitarbeiterkonto.",
  "app.account.emailSettings.error.403": "Zugriff verweigert.",
  "app.account.firstName": "Vorname",
  "app.account.lastName": "Nachname",
  "app.account.emailJob": "Firmen E-Mail",
  "app.account.privateEmail": "Private E-Mail",
  "app.account.enablePrivateEmail": "E-Mails an private Adresse versenden",
  "app.account.phone": "Telefon",
  "app.account.street": "Straße",
  "app.account.town": "Ort",
  "app.account.zip": "PLZ",
  "app.account.mobileJob": "(Rufnummer)",
  "app.account.mobileProviderJob": "Mobil (Vorwahl)",
  "app.account.accountOwner": "Kontoinhaber",
  "app.account.iban": "IBAN",
  "app.account.bic": "BIC",
  "app.account.accessData.headline": "Zugangsdaten",
  "app.account.username": "Benutzername",
  "app.account.password": "Neues Passwort",
  "app.account.passwordRepeat": "Passwort (Wiederholung)",
  "app.account.lastLogins": "Deine letzten 5 Logins",
  "app.account.passwordHint":
    "Das Passwort muss mind. aus 10 Zeichen mit 1 Groß- und Kleinbuchstaben, einer Zahl und einem Sonderzeichen ($@!%*#?&_.-) bestehen.",
  "app.account.usernameHint":
    "Der Benutzername muss aus mindestens 6 Zeichen bestehen und darf nur Buchstaben, Zahlen und folgende Sonderzeichen beinhalten -._",
  "app.account.save": "Speichern",
  "app.account.edit": "Kontodaten ändern",
  "app.account.changeUsername": "Benutzername ändern",
  "app.account.changePassword": "Passwort ändern",
  "app.account.invalidUsername": "Der Benutzername enthält ungültige Zeichen.",
  "app.account.invalidPassword": "Das Passwort genügt den Anforderungen nicht.",
  "app.account.invalidPasswordRepeat": "Die Passwörter stimmen nicht überein.",
  "app.account.saved": "Die Daten wurden gespeichert.",
  "app.account.failed":
    "Die Daten wurden nicht gespeichert. Bitte überprüfe Deine Eingaben.",
  "app.account.invalidIban": "Die IBAN ist ungültig.",
  "app.account.invalidBic": "Die BIC ist ungültig.",
  "app.account.incomplete": "Bitte vervollständige erst Deine Kontodaten!",
  "app.account.bank.help":
    "Du fragst dich, warum wir deine Kontodaten brauchen? Sobald du dir einen externen Gesundheitspartner ausgesucht hast und eine Erstattung im Portal anstößt, brauchen wir ein Bankkonto, auf dem wir deine Auslage gutschreiben können.",
  "app.account.blockMessage":
    "Die Daten wurden noch nicht gespeichert. Sicher dass du fortfahren möchtest?",
  "app.account.usernameChanged": "Dein Benutzername wurde geändert.",
  "app.account.passwordChanged": "Dein Passwort wurde geändert.",
  "app.account.emailSettingsSaved": "Einstellungen gespeichert.",
  "app.account.bankAccount": "Kontodaten",
  "app.calendar.title": "Kalender",
  "app.calendar.help":
    "Es werden im Kalender immer nur die nächsten 6 Wochen angezeigt.",
  "app.calendar.help.booked": "Diesen Termin hast du bereits gebucht.",
  "app.calendar.timeSlotTooltip":
    "In diesem Zeitfenster hast du bereits einen {duration}-minütigen Termin",
  "app.staticcontent.close": "Ok",
  "app.news.error.default": "Ein Fehler ist aufgetreten.",
  "app.news.error.400": "Leider ist ein Fehler aufgetreten (400).",
  "app.news.error.403": "Leider ist ein Fehler aufgetreten (403).",
  "app.news.title": "News",
  "app.news.markAsReadWarningTitle": "Achtung",
  "app.news.markAsReadWarningMessage":
    "Willst du wirklich dauerhaft ausblenden?",
  "app.news.markAsReadWarningShow": "Warnung nicht mehr anzeigen.",
  "app.news.noNews": "Es gibt aktuell keine News",
  "app.privacyPolicy.continue": "Weiter",
  "app.privacyPolicy.accept":
    "Ich haben die Datenschutzbestimmungen gelesen und stimme diesen zu.",
  "app.privacyPolicy.error.pleaseAccept.title":
    "Bitte akzeptiere die Datenschutzbestimmungen um fort zu fahren.",
  "app.privacyPolicy.error.pleaseAccept":
    "Bitte akzeptiere die Datenschutzbestimmungen um fort zu fahren.",
  "app.privacyPolicy.error.default": "Ein Fehler ist aufgetreten.",
  "app.privacyPolicy.error.403": "Leider ist ein Fehler aufgetreten (403).",
  "app.offer.search.empty":
    'Leider konnte zu deiner Suche "{search}" kein Angebot gefunden werden.',
  "app.offer.filter.empty":
    "Leider konnte zu deinen Filterkriterien kein Angebot gefunden werden.",
  "app.offer.searchFilter.empty":
    'Leider konnte zu deiner Suche "{search}" und deinen Filterkriterien kein Angebot gefunden werden.',
  "app.offers.filter.title": "Filter",
  "app.offers.filter.button": "Ergebnisse anzeigen",
  "app.offers.filter.reset": "zurücksetzen",
  "app.offers.filter.category": "Kategorie",
  "app.offers.filter.price": "Preis ",
  "app.offers.filter.price.to": " bis",
  "app.offers.filter.price.onlyBudget": "nur innerhalb meines Budgets",
  "app.offers.title": "Angebote",
  "app.offers.more": "weitere Angebote laden...",
  "app.offers.book": "Termin auswählen",
  "app.offers.bookAnother": "weiteren Termin buchen",
  "app.offers.book.error.bookLimitExceeded":
    "Du kannst dieses Datum/ diese Zeit nicht mehr buchen. Bitte wähle ein/e andere/s.",
  "app.offers.bookCourse": "Kurs auswählen",
  "app.offers.bookNotAvailable": "keine Termine verfügbar",
  "app.offers.uploadReceipt": "mehr Informationen & Belegupload",
  "app.offers.useExternal": "zur Buchung",
  "app.offers.description": "Beschreibung",
  "app.offers.images": "Bilder",
  "app.offers.extern.serviceProvider": "Anbieter",
  "app.offers.extern.price": "Preis",
  "app.offers.extern.note": "Hinweis für den Anbieter",
  "app.offers.extern.privacy": "Einverständniserklärung",
  "app.offers.extern.privacyPolicyAccepted":
    "Ich habe die Einverständniserklärung gelesen und akzeptiert.",
  "app.offers.extern.error.privacyPolicyAccepted":
    "Bitte stimmen Sie der Einverständniserklärung zum Datenschutz zu.",
  "app.offers.extern.error.selectPayment": "Das Budget ist nicht ausreichend.",
  "app.offers.extern.error.budgetLeft":
    "Leider ist Dein Budget für dieses Angebot nicht ausreichend",
  "app.offers.extern.error.default":
    "Leider ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
  "app.offers.extern.error.403":
    "Die aktuelle Berechtigung ist nicht ausreichend.",
  "app.offers.extern.error.400x01": "Bitte wähle ein gültige Zahlart.",
  "app.offers.extern.bookingDate": "Gebucht am",
  "app.offers.extern.state": "Status",
  "app.offers.extern.state.BOOKED": "übermittelt",
  "app.offers.extern.state.RUNNING": "bestätigt",
  "app.offers.extern.state.CANCELED": "storniert",
  "app.offers.extern.state.FINISHED": "beendet",
  "app.offers.extern.info.BOOKED": "Gebucht bis: ",
  "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantEventOnline":
    "Online Events",
  "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantEventLocation":
    "Präsenzveranstaltungen",
  "app.offers.extern.variants.BgmConceptTherapyOfferExternVariantCustom":
    "Optionen",
  "app.offers.extern.variants.begin": "Beginn",
  "app.offers.extern.variants.end": "Ende",
  "app.offers.extern.variants.platform": "Plattform",
  "app.offers.extern.variants.place": "Ort",
  "app.offers.extern.variants.custom": "Option",
  "app.offers.extern.variants.name": "Name",
  "app.offers.extern.variants.date": "Datum",
  "app.offers.extern.variants.dateBegin": "Beginn:",
  "app.offers.extern.variants.dateEnd": "Ende:",
  "app.offers.extern.variants.allPlaces": "alle Orte",
  "app.offers.extern.variants.location": "location",
  "app.offers.extern.variants.maps": "In Google Maps anzeigen",
  "app.offers.extern.variants.details": "Details",
  "app.offers.extern.variants.online": "online",
  "app.offers.extern.variants.sortBy": "Sortieren nach",
  "app.offers.criteria": "Förderkriterien",
  "app.offers.upload": "Beleg einreichen",
  "app.offers.upload.addMore": "mehr hinzufügen",
  "app.offers.upload.amountHelpText":
    "Bitte gib einen gültigen Betrag mit max. 2 Nachkommastellen an.",
  "app.offers.upload.duration": "Lernzeit",
  "app.offers.upload.duration.help": "Bitte gib deine Lernzeit in Minuten an.",
  "app.offers.upload.serviceProvider": "Anbieter",
  "app.offers.upload.serviceProvider.empty": "Anderer",
  "app.offers.upload.serviceProvider.pleaseChoose": "bitte wählen",
  "app.offers.upload.serviceProvider.required":
    'Bitte wähle einen Anbieter oder setzte die Auswahl auf "Anderer"',
  "app.offers.upload.attributes.required":
    "Bitte wähle eines oder mehrere der Attribute.",
  "app.offers.upload.attributes.pleaseChoose": "bitte wählen",
  "app.offers.uploadAccept":
    "Ich habe die Förderkriterien gelesen und akzeptiert.",
  "app.offers.offer": "Angebot",
  "app.offers.notAvailableNotice.button": "mehr Informationen",
  "app.offers.notAvailableNotice.title": "Aktuell nicht verfügbar",
  "app.offers.notAvailableNotice.text":
    "Dieses Angebot ist aktuell nicht verfügbar.",
  "app.offers.receiptDate": "Belegdatum",
  "app.offers.receiptTitle": "Titel",
  "app.offers.upload.receiptTitle.default": "{offer}",
  "app.offers.upload.receiptTitle.placeholder": "bitte eintragen",
  "app.offers.upload.attributes.help": "Klicke hier die passenden Optionen an.",
  "app.offers.upload.file": "Beleg",
  "app.offers.upload.file.help":
    "Max. 3 Dateien bis 5MB, nur PDF oder JPG bzw. JPEG möglich, keine Kontoauszüge.",
  "app.offers.provideBankAccount":
    "Bitte hinterlege zunächst deine Kontodaten unter ",
  "app.offers.upload.receiptNumber.help":
    "Solltest du keine Belegnr. haben, setze die ",
  "app.offers.uploadSuccessful": "Der Beleg wurde erfolgreich eingereicht.",
  "app.offers.uploadValidationError":
    "Dein Beleg wurde nicht eingereicht. Bitte prüfe Deine Eingaben.",
  "app.offers.uploadError":
    "Leider ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
  "app.offers.upload.error.transmission":
    "Leider ist ein Fehler bei der Übertragung aufgetreten. Bitte prüfe deine Netzerwerkverbindung.",
  "app.offers.upload.error.default":
    "Leider ist ein unbekannter Fehler aufgetreten. Bitte versuche es später erneut.",
  "app.offers.upload.error.422":
    "Leider konnte dein Upload nicht verarbeitet werden: Bitte füge eine PDF oder JPG Datei an.",
  "app.offers.upload.error.400x01":
    "Leider konnte dein Upload nicht verarbeitet werden: Bitte füge eine PDF oder JPG Datei an.",
  "app.offers.upload.error.400x02":
    "Leider konnte dein Upload nicht verarbeitet werden: Die Erstattungsform ist ungültig.",
  "app.offers.upload.error.400x03":
    "Leider konnte dein Upload nicht verarbeitet werden: Bitte lade ausschließlich pdf oder jpg hoch.",
  "app.offers.upload.error.400x04":
    "Leider konnte dein Upload nicht verarbeitet werden: Bitte lade nur Dateien mit max. 5MB hoch.",
  "app.offers.upload.error.400x05":
    "Leider konnte dein Upload nicht verarbeitet werden: Das Bildformat wird leider nicht unterstützt.",
  "app.offers.upload.error.400x06":
    "Leider konnte dein Upload nicht verarbeitet werden: Ein verschlüsseltes PDF wird aktuell nicht unterstützt.",
  "app.offers.upload.error.400x07":
    "Leider konnte dein Upload nicht verarbeitet werden: Bitte überprüfen Sie Ihre Eingaben.",
  "app.offers.upload.error.500x01":
    "Leider ist beim Verarbeiten deines Belegs ein Fehler aufgetreten. Bitte wende dich an das Service Team.",
  "app.offers.upload.error.500x02":
    "Leider ist bei der Verarbeitung deines Belegs ein Fehler aufgetreten. Bitte wende dich an das Service Team.",
  "app.offers.upload.error.500":
    "Leider ist ein Verarbeitungsfehler aufgetreten. Bitte wende dich an das Service Team.",
  "app.offers.upload.error.processing":
    "Leider ist beim Verarbeiten deines Belegs ein Fehler aufgetreten. Bitte wende dich an das Service Team.",
  "app.offers.criteriaNotAcceptedError":
    "Bitte akzeptiere die Förderkriterien.",
  "app.offers.receiptNumber": "Belegnr.",
  "app.offers.totalAmount": "Erstattungsbetrag",
  "app.offers.price": "Preis",
  "app.offers.price.price": "Fehlende Punkte",
  "app.offers.price.total": "Gesamtpreis",
  "app.offers.price.price.tooltip": "So viele Punkte fehlen leider",
  "app.offers.price.total.tooltip": "Das kostet Ihr Angebot",
  "app.offers.price.valid": "Gültig",
  "app.offers.price.valid.from": "von",
  "app.offers.price.valid.to": "bis",
  "app.offers.price.subvention": "Zuschuss",
  "app.offers.price.subventionSingular": "Punkt",
  "app.offers.price.subvention.tooltip":
    "Die Punkte werden deinem Budget direkt belastet.",
  "app.offers.price.course.price": "Preis",
  "app.offers.price.course.tooltip": " ",
  "app.offers.price.count": "Limitierung",
  "app.offers.price.count.tooltip":
    "Beschränkung für die aktuelle Bezuschussung",
  "app.offers.price.count.fac": "x",
  "app.offers.price.count.once": "einmalig",
  "app.offers.price.course.subvention": "app.offers.price.course.subvention",
  "app.offers.price.course.subvention.tooltip":
    "Da du eine Staffel buchst, wird der gesamte Punktebetrag für alle Termine direkt deinem Punktekonto belastet.",
  "app.offers.price.course.fromTo": "Kurs vom {from} bis {to}",
  "app.offers.price.course.budget": "app.offers.price.course.budget",
  "app.offers.price.course.budget.tooltip":
    "Da du eine Staffel buchst, wird der gesamte Punktebetrag für alle Termine direkt deinem Punktekonto belastet.",
  "app.offers.price.date.at": "Termin am",
  "app.offers.price.receipt.fromOver": "Beleg vom {from} über {over} EUR",
  "app.offers.price.visited.at": "Besuch vom",
  "app.offers.price.refund.state": "Status",
  "app.offers.price.refund.submittedAt": "Eingereicht am",
  "app.offers.price.refund.amount": "Betrag",
  "app.offers.price.refund.referenceNumber": "Belegnummer",
  "app.offers.price.refund.price": "app.offers.price.refund.price",
  "app.offers.price.refund.price.tooltip":
    "Dir werden genauso viele Punkte belastet wie dein Training/Kurs extern in Euro kostet.",
  "app.offers.price.refund.text": "1 Punkt = 1 Euro",
  "app.offers.price.budget": "Budget",
  "app.offers.price.budget.tooltip":
    "Dieser Punktebtrag wird direkt von deinem Punktekonto belastet.",
  "app.offers.price.availableBudget": "Mein aktuelles Budget",
  "app.offers.videos": "Videos",
  "app.offers.downloads": "Downloads",
  "app.offers.date.book": "Buchen",
  "app.offers.date.edit": "Termin bearbeiten",
  "app.offers.date.renew": "Verlängern",
  "app.offers.date.reserve": "Vormerken",
  "app.offers.date.reserve.uncommitted": "Unverbindlich vormerken",
  "app.offers.date.reserve.committed": "Verbindlich vormerken",
  "app.offers.date.reserve.ONE_DAY": "Bis zu einem Tag vor Beginn",
  "app.offers.date.reserve.RESERVATION_FOUR_HOURS":
    "Bis zu 4 Stunden vor Beginn",
  "app.offers.date.reserve.RESERVATION_ONE_HOUR": "Bis zu 1 Stunde vor Beginn",
  "app.offers.date.reserve.text":
    "Dieser Termin ist leider bereits ausgebucht! Möchten Sie auf die Warteliste gesetzt werden?",
  "app.offers.date.book.info":
    "Du kannst maximal 8 Wochen im Voraus Termine buchen.",
  "app.offers.date.date": "Datum",
  "app.offers.date.time": "Uhrzeit",
  "app.offers.date.time.available": "verfügbar",
  "app.offers.date.time.not_available": "nicht verfügbar",
  "app.offers.date.time.booked": "gebucht",
  "app.offers.date.time.info":
    "Hinweis: Es sind mehrere Termine für die selbe Uhrzeit verfügbar.",
  "app.offers.bookError": "Fehler beim Versuch zu buchen.",
  "app.offers.booking.error.default": "Fehler beim Versuch zu buchen.",
  "app.offers.booking.error.400x00": "Diese Buchung besteht bereits.",
  "app.offers.booking.error.400x02":
    "Du kannst nichts in der Vergangenheit buchen.",
  "app.offers.booking.error.400x03":
    "Der Termin ist leider nicht mehr verfügbar.",
  "app.offers.booking.error.400x09":
    "Das Maximum an Terminen für diesen Tag wurde erreicht.",
  "app.offers.booking.error.400x05":
    "Das aktuelle Guthaben ist nicht ausreichend.",
  "app.offers.booking.error.400x06":
    "Das aktuelle Budget ist nicht ausreichend.",
  "app.offers.booking.error.400x07": "Bitte hinterlege eine Bankverbindung.",
  "app.offers.booking.error.400x10":
    "Die Beschränkungen des Angebots werden nicht erfüllt.",
  "app.offers.booking.error.403":
    "Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.",
  "app.offers.booking.error.500":
    "Leider ist bei der Buchung ein Fehler aufgetreten. Bitte versuche es später erneut.",
  "app.offers.bookSuccess": "Erfolgreich gebucht.",
  "app.offers.course.eventDates": "Die Termine im einzelnen",
  "app.offers.course.date": "Kurs Zeitraum",
  "app.offers.course.booked": "Du hast diesen Kurs bereits gebucht.",
  "app.offers.course.notAvailable":
    "Der gewählte Kurszeitraum ist leider bereits ausgebucht.",
  "app.offers.noNote": "",
  "app.offers.info": "Deine Informationen für den Trainer/Betriebsarzt",
  "app.offers.times": "Uhrzeit",
  "app.offers.noinLimit":
    "Du erfüllst nicht die Beschränkungen dieses Angebots",
  "app.offers.global.error.402":
    "Du hast aktuell keine gültige Buchung für dieses Angebot. Bitte buche das Angebot, um fortzufahren.",
  "app.offers.comment": "Nachricht",
  "app.offers.upload.comment.placeholder":
    "Hier kannst Du Deinem Upload eine Nachricht beifügen...",
  "app.offers.phone": "Deine Telefonnummer",
  "app.offers.phoneRequired": "Bitte gebe deine Telefonnummer an.",
  "app.home.title": "Home",
  "app.home.welcome": "Willkommen",
  "app.home.nextDatePhrase": " von {from} - {to}Uhr",
  "app.home.allAppointments": "Alle Termine",
  "app.home.manageCredit": "Mein Budget",
  "app.home.widget.nextDate.button": "Meine Termine",
  "app.home.widget.nextDate.title": "Mein nächster Termin",
  "app.home.widget.categories.title": "Angebote:",
  "app.home.widget.highlightEvents.title": "Aktuell verfügbar",
  "app.home.widget.highlightEvents.button": "Meine Angebote",
  "app.home.widget.budget.title": "Mein Budget",
  "app.home.widget.budget.button": "Budget verwalten",
  "app.home.widget.nextDate.button.offers": "zu den Angeboten",
  "app.home.widget.nextDate.joinOnlineEventButton": "jetzt beitreten",
  "app.home.widget.nextDate.joinOnlineEventButton.hintCanJoin":
    "Dem Online Training jetzt beitreten!",
  "app.home.widget.nextDate.joinOnlineEventButton.hintCantJoin":
    "Noch kann dem Online Training nicht beigetreten werden...",
  "app.home.widget.highlightEvents.button.book": "buchen",
  "app.home.widget.credit.title": "Mein Guthaben",
  "app.home.widget.credit.minutes": "Min.",
  "app.home.widget.search.title": "Suche",
  "app.home.widget.search.placeholder": "Suche...",
  "app.home.widget.search.button": "go!",
  "app.home.widget.offerBanners.title": "{offer}",
  "app.home.widget.offerBanners.text": "{offer} - jetzt mehr erfahren!",
  "app.bonus.collect.collegues.title": "Kollegen werben",
  "app.bonus.collect.collegues.hint":
    "Sichern Sie sich Bonuspunkte, wenn sich ein Kollege registriert und nochmal nach seinem ersten Termin!",
  "app.bonus.collect.birthday.title": "Geburtstagsbonus",
  "app.bonus.collect.birthday.hint":
    "Sichern Sie sich 125 Bonuspunkte zu Ihrem Geburtstag!",
  "app.navigation.bonus.title": "Meine Prämien",
  "app.bonus.title": "Meine Prämien",
  "app.bonus.nav.shop": "Prämien shoppen",
  "app.bonus.nav.collections": "Historie verfolgen",
  "app.bonus.nav.conversions": "Bestellungen einsehen",
  "app.bonus.nav.collect": "Punkte sammeln",
  "app.bonus.availablePoints": "Verfügbare Punkte",
  "app.bonus.points": "Punkte",
  "app.bonus.noCollections": "Sie haben bisher keine Bonuspunkte gesammelt.",
  "app.bonus.noConversions": "Sie haben bisher keine Punkte eingelöst.",
  "app.bonus.shop.buy": "einlösen",
  "app.bonus.shop.category1000": "bis 1000 Punkte",
  "app.bonus.shop.category2000": "bis 2000 Punkte",
  "app.bonus.shop.category3000": "bis 3000 Punkte",
  "app.bonus.shop.category4000": "bis 4000 Punkte",
  "app.bonus.shop.category5000": "bis 5000 Punkte",
  "app.bonus.shop.category6000": "bis 6000 Punkte",
  "app.bonus.shop.category7000": "bis 7000 Punkte",
  "app.bonus.shop.category8000": "bis 8000 Punkte",
  "app.bonus.shop.category9000": "bis 9000 Punkte",
  "app.bonus.shop.category10000": "ab 9000 Punkte",
  "app.bonus.shop.confirm":
    "Möchten Sie Ihre Bonuspunkte für die gewählte Prämie einlösen?",
  "app.bonus.shop.convert": "einlösen",
  "app.bonus.shop.error.400x00": "Bitte prüfen Sie Ihre Eingaben.",
  "app.bonus.shop.error.400x01":
    "Leider ist die gewählte Prämie nicht mehr verfügbar.",
  "app.bonus.shop.error.400x02":
    "Bitte hinterlegen Sie zunächst Ihre Postanschrift.",
  "app.bonus.shop.error.400x03":
    "Leider verfügen Sie nicht über ausreichend Punkte.",
  "app.bonus.shop.deliverAddress": "Lieferadresse:",
  "app.bonus.shop.deliver.town": "Ort",
  "app.bonus.shop.deliver.zip": "PLZ",
  "app.bonus.shop.deliver.street": "Straße",
  "app.bonus.shop.imediately": "Die Prämie steht Ihnen sofort zur Verfügung.",
  "app.global.abort": "abbrechen",
  "app.bonus.shop.success": "Die Prämie wurde erfolgreich bestellt!",
  "app.bonus.shop.notAvailable":
    "Leider sind die aktuellen Punkte für die Prämie nicht ausreichend.",
  "app.bonus.widget.label.points": "Aktueller Punktestand: ",
  "app.bonus.widget.label.shop": "Prämien shoppen",
  "app.bonus.widget.label.collect": "Punkte sammeln",
  "app.bonus.widget.label.collegues": "Kollegen empfehlen",
  "app.bonus.collect.birthday.existsHint":
    "Sie haben Ihren Geburtstag bereits hinterlegt und können sich auf die Extrapunkte freuen!",
  "app.bonus.collect.birthday.save": "hinterlegen",
  "app.bonus.collect.recommand.contact": "Kontaktdaten Ihres Kollegen:",
  "app.bonus.collect.recommand.emailTo.label": "Firmen E-Mail Adresse:",
  "app.bonus.collect.recommand.nameTo.label": "Name:",
  "app.bonus.collect.recommand.message": "Ihre persönliche Nachricht:",
  "app.bonus.collect.recommand.preview": "Vorschau:",
  "app.bonus.collect.recommand.preview.legal": "*HAFTUNGSAUSSCHLUSS*",
  "app.bonus.collect.recommand.preview.link": "*LINK*",
  "app.bonus.collect.recommand.preview.salutation": "Hallo",
  "app.bonus.collect.recommand.preview.greeting": "Viele Grüße",
  "app.bonus.collect.recommand.success":
    "Ihre Empfehlung wurde erfolgreich versandt.",
  "app.bonus.collect.recommand.error":
    "Leider ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Eingaben.",
  "app.bonus.collect.recommend.send": "abschicken",
  "app.bonus.collect.recommand.message.template":
    "kennst du schon das tolle BGM Angebot unserer Firma? Registriere dich jetzt!",
  "app.home.widget.todayEvents.title": "Aktuelle Termine",
  "app.home.widget.todayEvents.button": "zum Kalender",
  "app.home.widget.bonus.title": "Meine Bonuspunkte",
  "app.home.widget.bonus.button": "jetzt einlösen",
  "app.home.noNextDate": "kein bevorstehender Termin",
  "app.home.noAppointmentsToday": "Keine Termine heute",
  "app.password.reset": "Passwort vergessen?",
  "app.password.request": "Neues Passwort anfordern",
  "app.password.forgotUsername": "Benutzername vergessen?",
  "app.password.reset.email.help":
    "Bitte beachte: Hast Du in Deinem Account eine private E-Mail Adresse hinterlegt, so musst Du hier diese eintragen.",
  "app.password.reset.username.help":
    "Bitte trage Deinen Benutzernamen oder Deine betriebliche E-Mail Adresse ein.",
  "app.password.set.error.default": "Passwort konnte nicht gesetzt werden",
  "app.password.set.error.400x01": "Bitte gib ein Passwort ein.",
  "app.password.set.error.400x02":
    "Das neue Passwort entspricht leider nicht den Anforderungen.",
  "app.password.set.error.400x03":
    "Der Link zum setzen des neuen Passworts ist abgelaufen.",
  "app.password.set.error.403":
    "Bitte wenden dich an den Support um dein Passwort zurück zu setzen.",
  "app.password.set.emptyUsername": "Der Benutzername darf nicht leer sein.",
  "app.password.reset.error.default":
    "Password konnte nicht zurückgesetzt werden.",
  "app.password.or": "ODER",
  "app.password.reset.error.400x01":
    "Bitte wenden dich an den Support um dein Passwort zurück zu setzen.",
  "app.password.reset.error.403":
    "Bitte wenden dich an den Support um dein Passwort zurück zu setzen.",
  "app.password.reset.error.403x01":
    "Ein neues Passwort kann erst in 2 Stunden angefordert werden.",
  "app.password.reset.error.emptyUsername":
    "Benutzername / E-Mail Adresse darf nicht leer sein.",
  "app.password.reset.error.emptyEmail": "Email darf nicht leer sein.",
  "app.password.reset.success":
    "Sofern deine Angaben korrekt waren, wurden neue Zugangsdaten gesendet. Bedenke, das das Passwort nur alle 2 Stunden zurückgesetzt werden kann.",
  "app.password.set.fail":
    "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.",
  "app.password.set.success": "Das neue Passwort wurde gespeichert.",
  "app.password.set": "Passwort speichern",
  "app.password.set.title": "Neues Passwort festlegen",
  "app.password.reset.title": "Zugangsdaten vergessen?",
  "app.password.reset.username": "Benutzername / E-Mail Adresse",
  "app.password.reset.email": "E-Mail Adresse",
  "app.password.reset.support":
    "mailto:email@gebu.bgm.services?subject=Benutzername%20vergessen&body=Hallo%20GeBu%20Team%2C%0D%0Aich%20habe%20leider%20meine%20Zugangsdaten%20vergessen.%0D%0ABitte%20sendet%20mir%20eine%20E-Mail%20mit%20meinem%20Benutzernamen%20und%20einem%20Link%20zum%20Zur%C3%BCcksetzen%20des%20Passwortes.%0D%0A%0D%0ADanke%20und%20viele%20Gr%C3%BC%C3%9Fe%0D%0A%0D%0A%0D%0A",
  "app.password.reset.password": "Passwort",
  "app.appointments.title": "Meine Termine",
  "app.appointments.reservation": "Reservierung",
  "app.appointments.selectTimeSlot": "Bitte wähle eine andere Uhrzeit",
  "app.appointments.save": "Speichern",
  "app.appointments.updated": "Der Termin wurde geändert.",
  "app.appointments.update.error.default":
    "Der Termin konnte nicht geupdated werden.",
  "app.appointments.update.error.403": "Zugriff verweigert.",
  "app.appointments.update.error.404":
    "Die Terminbuchung wurde nicht gefunden.",
  "app.appointments.update.error.400x00": "Leider ist ein Fehler aufgetreten.",
  "app.appointments.update.error.400x02":
    "Ein Termin in der Vergangenheit kann nicht geändert werden.",
  "app.appointments.update.error.400x03":
    "Der Termin ist leider nicht mehr verfügbar.",
  "app.appointments.update.error.400x05":
    "Das aktuelle Guthaben ist nicht ausreichend.",
  "app.appointments.update.error.400x06":
    "Das aktuelle Budget ist nicht ausreichend.",
  "app.appointments.update.error.400x07":
    "Bitte hinterlege zunächst deine IBAN und BIC unter ",
  "app.appointments.update.error.400x09":
    "Das Maximum an der Terminen für diesen Tag wurde erreicht.",
  "app.appointments.appointment": "Termin",
  "app.appointments.therapist": "Mit:",
  "app.appointments.dateTimePhrase": "{day} {date}, um {from}Uhr",
  "app.appointments.note": "Hinweis",
  "app.appointments.noAppointments":
    "Aktuell hast du keine internen Termine gebucht.",
  "app.appointments.appointmentCanceled": "Deine Buchung wurde storniert.",
  "app.appointments.courseCanceled": "Deine Kursteilnahme wird storniert.",
  "app.appointments.paymentMethod.error.sepaMandateRequired":
    'Zur Buchung wird ein SEPA-Lastschriftmandat benötigt. Bitte richte dieses zunächst unter "Mein Konto" ein.',
  "app.appointments.paymentMethod": "Bezahlverfahren",
  "app.appointments.paymentMethod.INVOICE": "Rechnung",
  "app.appointments.paymentMethod.AGENT": "Agent",
  "app.appointments.paymentMethod.DIRECT_DEBIT": "Lastschrift",
  "app.appointments.paymentMethod.CASH": "Bar",
  "app.appointments.paymentMethod.SUBVENTION": "Budget",
  "app.appointments.paymentMethod.CREDIT": "Guthaben",
  "app.appointments.roomInfo": "Raum/Treffpunkt:",
  "app.appointments.roomInfo.COURSE": "Kurs",
  "app.appointments.roomInfo.EVENT": "Event",
  "app.appointments.roomInfo.DEFAULT": "Termin",
  "app.appointments.guestInfo": "Kommentar",
  "app.appointments.binding": "Verbindlichkeit",
  "app.appointments.binding.NO_BINDING": "unverbindlich",
  "app.appointments.binding.ONE_DAY": "Reservierung bis ein Tag zuvor",
  "app.appointments.monday": "Montag",
  "app.appointments.tuesday": "Dienstag",
  "app.appointments.wednesday": "Mittwoch",
  "app.appointments.thursday": "Donnerstag",
  "app.appointments.friday": "Freitag",
  "app.appointments.saturday": "Samstag",
  "app.appointments.sunday": "Sonntag",
  "app.appointments.binding.RESERVATION_FOUR_HOURS":
    "Reservierung bis vier Stunden zuvor",
  "app.appointments.binding.RESERVATION_ONE_HOUR":
    "Reservierung bis eine Stunde zuvor",
  "app.appointments.editAppointment": "Termin bearbeiten",
  "app.appointments.editAppointment.noTimeSlots": "Keine Uhrzeit gegeben",
  "app.appointments.editAppointment.selectTimeSlot": "Bitte wähle eine Uhrzeit",
  "app.appointments.cancelAppointment": "Termin stornieren",
  "app.appointments.cancelReservation": "Reservierung stornieren",
  "app.appointments.cancelAppointment.error.default":
    "Der Termin konnte leider nicht storniert werden.",
  "app.appointments.cancelAppointment.error.403": "Zugriff verweigert.",
  "app.appointments.cancelAppointment.error.404":
    "Die Terminbuchung ist unbekannt.",
  "app.appointments.cancelAppointment.error.400x00":
    "Leider ist ein Fehler aufgetreten.",
  "app.appointments.cancelAppointment.error.400x01":
    "Termine in der Vergangenheit können nicht storniert werden.",
  "app.appointments.confirmCancelAppointment":
    "Möchtest du den Termin wirklich stornieren?",
  "app.appointments.confirmCancelReservation":
    "Möchtest du die Reservierung wirklich stornieren?",
  "app.appointments.confirmCancelCourse":
    "Möchtest du dich wirklich von der gesamten Staffel abmelden?",
  "app.appointments.editCourse": "Kursbuchung bearbeiten",
  "app.appointments.cancelCourse": "Kursbuchung stornieren",
  "app.appointments.cancelCourse.error.default":
    "Die Staffel konnte leider nicht storniert werden.",
  "app.appointments.cancelCourse.error.403": "Zugriff verweigert.",
  "app.appointments.cancelCourse.error.404":
    "Die Staffelbuchung ist unbekannt.",
  "app.appointments.cancelCourse.error.404x01":
    "Es wurden keine Buchungen für diese Staffel gefunden.",
  "app.appointments.cancelCourse.error.400x00":
    "Leider ist ein Fehler aufgetreten.",
  "app.appointments.cancelCourse.error.400x01":
    "Termine in der Vergangenheit können nicht storniert werden.",
  "app.appointments.paymentMethod.default": "Bezahlart Auswählen",
  "app.appointments.course.bookedUp": "Leider ist der Kurs bereits ausgebucht",
  "app.appointments.course.bookedByYou": "Du hast diesen Kurs bereits gebucht",
  "app.budget.title": "Mein Budget",
  "app.budget.currentBudget": "Aktuelles Budget",
  "app.budget.overview": "Budget Übersicht",
  "app.budget.submittedReceipts": "Eingereichte Belege",
  "app.budget.of": "von",
  "app.budget.points": "Punkten",
  "app.budget.state.inProgress.header": "in Bearbeitung",
  "app.budget.state.rejectedRequirements.header": "abgelehnt",
  "app.budget.state.rejectedSubventionLimit.header": "abgelehnt",
  "app.budget.state.rejectedUnknownOffer.header": "abgelehnt",
  "app.budget.state.rejectedDuplicate.header": "abgelehnt",
  "app.budget.state.refund.header": "ausgezahlt",
  "app.budget.state.approved.header": "positiv geprüft",
  "app.budget.state.waiting.header": "wartend auf Rückmeldung",
  "app.budget.state.inProgress.content": "Der Beleg wird bearbeitet",
  "app.budget.state.rejectedRequirements.content":
    "Leider erfüllt dein Beleg die Förderkriterien nicht.",
  "app.budget.state.rejectedSubventionLimit.content":
    "Leider ist dein Budget für diesen Zeitraum erschöpft.",
  "app.budget.state.rejectedUnknownOffer.content":
    "Dein Beleg konnte leider keinem Angebot aus dem Förderkatalog zugeordnet werden.",
  "app.budget.state.rejectedDuplicate.content":
    "Der Beleg wurde bereits eingereicht.",
  "app.budget.state.refund.content": "Der Betrag wurde ausgezahlt.",
  "app.budget.state.approved.content":
    "Deine Erstattung wurde freigegeben und wird ausgezahlt.",
  "app.budget.state.waiting.content":
    "Zur weiteren Bearbeitung ist eine Rückmeldung deinerseits erforderlich. Bitte prüfe deine E-Mails  oder kontaktiere uns direkt.",
  "app.budget.noBudget": "Du hast noch kein Budget ausgegeben.",
  "app.budget.noRefund": "Du hast noch keinen Beleg eingereicht.",
  "app.faq.title": "FAQ",
  "app.faq.search.placeholder": "Suchen...",
  "app.icon.title.filter": "Angebote filtern",
  "app.icon.title.64x64_rgb_extern": "externes Angebot",
  "app.icon.title.64x64_rgb_inhouse": "internes Angebot",
  "app.icon.date-edit": "In diesem Zeitfenster hast du bereits einen Termin.",
  "app.home.widget.companyEvent.button": "zum Event",
  "app.home.widget.companyEvent.headline": "Eine Auswahl:",
  "app.survey.questionnaire.question.bodyzones.label.empty": "bitte klicken",
  "app.survey.questionnaire.question.bodyzones.label.green":
    "keine Beschwerden",
  "app.survey.questionnaire.question.bodyzones.label.yellow":
    "selten Beschwerden",
  "app.survey.questionnaire.question.bodyzones.label.red": "oft Beschwerden",
  "app.survey.questionnaire.question.bodyzones.label.back": "Rückseite",
  "app.survey.questionnaire.question.bodyzones.label.front": "Vorderseite",
  "app.survey.title": "Umfrage",
  "app.survey.start": "weiter",
  "app.survey.code.error.auto":
    "Leider ist beim Laden ein Fehler aufgetreten.<br />Bitte versuchen Sie es später noch einmal.",
  "app.survey.error.null":
    "Leider ist beim Laden ein Fehler aufgetreten.<br />Bitte versuchen Sie es später noch einmal.",
  "app.survey.questionnaire.error": "Fehler!",
  "app.survey.questionnaire.error.403x01":
    "Leider ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen es noch einmal.",
  "app.survey.questionnaire.error.403x02":
    "Leider ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen es noch einmal.",
  "app.survey.questionnaire.error.403x03":
    "Leider ist ihr Code bereits abgelaufen.",
  "app.survey.questionnaire.error.403x04":
    "Leider ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen es noch einmal.",
  "app.survey.questionnaire.error.400": "Bitte überprüfen Sie Ihre Eingaben.",
  "app.survey.questionnaire.error.default":
    "Leider ist ein Fehler aufgetreten.<br />Bitte versuchen Sie es erneut.",
  "app.survey.questionnaire.question.yes": "ja",
  "app.survey.questionnaire.question.no": "nein",
  "app.survey.questionnaire.error.required":
    "Bitte vervollständigen Sie die Eingabe.",
  "app.survey.vote.error.null":
    "Leider ist beim Laden ein Fehler aufgetreten.<br />Bitte versuchen Sie es später noch einmal.",
  "app.survey.vote.error.key":
    "Leider ist der verwendete Link ungültig. <br />Bitte prüfen Sie den Link und versuchen Sie es erneut.",
  "app.survey.vote.error.403":
    "Leider ist der verwendete Link ungültig oder abgelaufen.",
  "app.survey.vote.error.400":
    "Sie haben bereits für diese Umfrage abgestimmt.",
  "app.survey.questionnaire.next": "weiter",
  "app.survey.questionnaire.question.unknownType":
    "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "app.survey.questionnaire.question.text.placeholder": "...",
  "app.survey.questionnaire.question.longtext.placeholder": "...",
  "app.survey.questionnaire.question.number.placeholder": "0",
  "app.survey.questionnaire.question.dropdown.placeholder": "bitte wählen",
  "app.survey.questionnaire.tooltip.incomplete":
    "Bitte vervollständige zunächst den Fragebogen.",
  "app.survey.questionnaire.progress": "ca. {minutes} Minuten verbleibend",
  "app.survey.questionnaire.progress.finished": "abgeschlossen",
  "app.navigation.imprint.title": "Impressum",
  "app.navigation.privacy.title": "Datenschutzbestimmungen",
  "app.navigation.faq.title": "FAQ",
  "app.navigation.contact.title": "Kontakt",
  "app.navigation.about.title": "Über das Gesundheitsbudget",
  "app.navigation.pro54.title": "Über pro54",
  "app.navigation.home.title": "Home",
  "app.navigation.offers.title": "Angebote",
  "app.navigation.calendar.title": "Kalender",
  "app.navigation.appointments.title": "Meine Termine",
  "app.appointments.futureAppointments": "Meine zukünftigen Termine",
  "app.appointments.pastAppointments": "Meine vergangenen Termine",
  "app.appointments.prescriptions": "Meine Rezepte",
  "app.navigation.subventions.title": "Mein Budget",
  "app.navigation.news.title": "News",
  "app.navigation.account.title": "Mein Konto",
  "app.global.logout": "Logout",
  "app.global.backToLogin": "Zurück zum Login",
  "app.navigation.guestcards.title": "Mein Guthaben",
  "app.login.register": "Noch keinen Zugang?",
  "app.register.title": "Registrierung",
  "app.register.registerKey.label":
    "Schritt 1 von 3 - Geben Sie Ihr Registrierungskennwort an:",
  "app.register.registerKey.hint":
    "Sie finden das Kennwort im Intranet oder erhalten es von Ihrem BGM Ansprechpartner",
  "app.register.registerKey.submit": "prüfen",
  "app.register.registerKey.placeholder": "Kennwort",
  "app.register.registerKey.error":
    "Leider ist Ihr Kennwort ungültig. Bitte prüfen Sie den Link oder Ihre Eingabe!",
  "app.register.identifier.label":
    "Schritt 2 von 3 - Geben Sie Ihre Emailadresse an:",
  "app.register.identifier.hint":
    "Zur Prüfung Ihrer Berechtigung ist nur eine Firmenadresse gültig. Sie können später eine private E-Mail Adresse hinterlegen.",
  "app.register.identifier.submit": "prüfen",
  "app.register.identifier.placeholder": "ihr.name@firma.com",
  "app.register.identifier.error":
    "Leider ist Ihre E-Mail Adresse nicht freigegeben. Bitte prüfen Sie Ihre Eingabe oder wenden Sie sich an Ihren BGM Ansprechpartner",
  "app.register.registerForm.label":
    "Schritt 3 von 3 - Geben Sie Ihre persönlichen Daten an:",
  "app.register.registerForm.submit": "registrieren",
  "app.register.registerKey.error.404":
    "Leider ist Ihr Kennwort ungültig. Bitte prüfen Sie den Link oder Ihre Eingabe!",
  "app.register.registerForm.group.company": "Zum Unternehmen",
  "app.register.registerForm.group.personal": "Zu Ihrer Person",
  "app.register.registerForm.group.contact": "Zur Kontaktaufnahme",
  "app.register.registerForm.group.account": "Zum Einloggen",
  "app.register.registerForm.group.privacyPolicy": "Zum Schutz Ihrer Daten",
  "app.register.registerForm.emailJob.label": "Firmen-E-Mail-Adresse",
  "app.register.registerForm.firstname.label": "Vorname",
  "app.register.registerForm.firstname.placeholder": "Vorname",
  "app.register.registerForm.firstname.hint": "",
  "app.register.registerForm.lastname.hint": "",
  "app.register.registerForm.lastname.label": "Nachname",
  "app.register.registerForm.lastname.placeholder": "Nachname",
  "app.register.registerForm.gender.hint": "",
  "app.register.registerForm.gender.label": "Anrede",
  "app.register.registerForm.gender.male": "Herr",
  "app.register.registerForm.gender.female": "Frau",
  "app.register.registerForm.companyId.hint": "",
  "app.register.registerForm.companyId.label": "Unternehmen",
  "app.register.registerForm.locationId.hint": "",
  "app.register.registerForm.locationId.label": "Standort",
  "app.register.registerForm.username.hint":
    "Bitte wählen Sie einen Nutzernamen. Es sind Buchstaben, Zahlen, sowie die Zeichen .-_ erlaubt.",
  "app.register.registerForm.username.label": "Benutzername",
  "app.register.registerForm.username.placeholder": "Benutzername",
  "app.register.registerForm.password.hint":
    "Das Passwort muss 10 Zeichen, einen Groß-, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
  "app.register.registerForm.password.label": "Passwort",
  "app.register.registerForm.password.placeholder": "Passwort",
  "app.register.registerForm.passwordRepeat.hint":
    "Bitte wiederholen Sie ihr Passwort.",
  "app.register.registerForm.passwordRepeat.label": "Passwort Wiederholung",
  "app.register.registerForm.passwordRepeat.placeholder": "Passwort",
  "app.register.registerForm.phoneJob.hint":
    "Bitte geben Sie nur Ihre Durchwahl an.",
  "app.register.registerForm.phoneJob.label": "Durchwahl",
  "app.register.registerForm.phoneJob.placeholder": "Durchwahl",
  "app.register.registerForm.mobile.hint":
    "Sie möchten kurzfristige Benachrichtigungen per SMS? Dann tragen Sie hier eine Inlands-Mobilnummer ein.",
  "app.register.registerForm.mobile.label": "Mobil",
  "app.register.registerForm.mobile.placeholder": "Handynummer",
  "app.register.registerForm.privacyPolicy.label":
    "Ich habe die Datenschutzbestimmungen gelesen und akzeptiert.",
  "app.register.registerForm.privacyPolicy.hint":
    "Mit einem Klick auf den Link gelangen Sie zu der Einverständniserklärung.",
  "app.register.registerForm.error.passwordRepeat":
    "Die eingegebenen Passwörter stimmen nicht überein.",
  "app.register.registerForm.success.title": "Registrierung erfolgreich!",
  "app.register.registerForm.success.content":
    "Sie haben sich erfolgreich registriert.<br />In den nächsten Minuten erhalten Sie eine E-Mail zur Aktivierung ihres Accounts.",
  "app.register.registerForm.error.400x04":
    "Dieser Benutzername ist nicht verfügbar.",
  "app.register.registerForm.error.500":
    "Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unser Serviceteam.",
  "app.register.registerForm.error.400x00":
    "Bitte prüfen Sie die Gültigkeit Ihrer Eingaben",
  "app.register.registerForm.error.500x01":
    "Leider gibt es Probleme mit Ihrem Account. Bitte wenden Sie sich an unser Serviceteam.",
  "app.guestcards.title": "Mein Guthaben",
  "app.guestcards.nav.list": "Meine Käufe",
  "app.guestcards.empty": "Sie haben noch kein Guthaben erworben.",
  "app.guestscards.label.salesDate": "Verkauft am:",
  "app.guestscards.label.used": "eingelöst:",
  "app.guestscards.label.blockDate": "gültig bis:",
  "app.prescriptions.noPrescriptions":
    "Sie haben bisher kein Privatrezept eingereicht.",
  "app.prescriptions.upload.button": "Privatrezept einreichen",
  "app.prescriptions.upload.title": "Privatrezept einreichen",
  "app.prescriptions.upload.prescriptionText.label": "Leistung:",
  "app.prescriptions.upload.prescriptionText.help":
    "Bitte geben Sie die Behandlungsform wie auf dem Rezept vermerkt an. Für mehrere Behandlungsarten reichen Sie das Rezept bitte nochmals seperat ein.",
  "app.prescriptions.upload.prescriptionDate.label": "Rezeptdatum:",
  "app.prescriptions.upload.prescriptionCredit.label": "Anzahl:",
  "app.prescriptions.upload.prescriptionCredit.help":
    "Bitte geben Sie die Anzahl der verordneten Behandlungen für diese Behandlungsform ein.",
  "app.prescriptions.upload.file.help":
    "Max. Dateien bis 5MB, nur PDF oder JPG bzw. JPEG möglich.",
  "app.prescriptions.upload.submit": "einreichen",
  "app.prescriptions.upload.error.transmission":
    "Leider ist bei der Übertragung ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "app.prescriptions.upload.successful":
    "Ihr Rezept wurde erfolgreich übertragen.",
  "app.prescriptions.checked.label": "Geprüft:",
  "app.prescriptions.dates.label": "Termine:",
  "app.prescriptions.rejected": "(abgelehnt)",
  "app.prescriptions.rejectedInfo":
    "Leider mussten wir Ihr Rezept ablehnen. Für Details setzen Sie sich bitte mit unserem Kundenservice in Verbindung.",
  "app.prescriptions.downloadReceipt": "Quittung herunterladen",
  "app.coupon.invoke.button": "Gutschein einlösen",
  "app.coupon.invoke.title": "Gutschein einlösen",
  "app.coupon.invoke.code.label": "Code:",
  "app.coupon.invoke.code.help":
    "Sie finden den Code unten mittig, auf Ihrem Gutschein",
  "app.coupon.invoke.code.placeholder": "ABCD-EFGH-IJKL-MNOP",
  "app.coupon.invoke.submit": "einlösen",
  "app.coupon.invoke.error.403":
    "Leider ist uns dieser Gutscheincode nicht bekannt.",
  "app.coupon.invoke.error.400":
    "Leider ist uns dieser Gutscheincode nicht bekannt.",
  "app.coupon.invoke.error.transmission":
    "Leider ist bei der Übertragung ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "app.coupon.invoke.error.processing":
    "Leider ist bei der Verarbeitung ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "app.coupon.invoke.successful": "Ihr Gutschein wurde erfolgreich aktiviert.",
  "app.onlineMeeting.title": "OnlineEvent",
  "app.onlineMeeting.error.403":
    "Du hast keine Berechtigung diesem Meeting beizutreten.",
  "app.onlineMeeting.error.404":
    "Das angeforderte Meeting wurde nicht gefunden.",
  "app.onlineMeeting.error.default":
    "Beim Betreten des Meetings ist ein Fehler aufgetreten.<br />Bitte versuche es später erneut.",
  "app.test.test.test": "dies ist ein test",
  "app.icon.title.home": "Das aktuelle Budget ist nicht ausreichend.",
  "app.privacyPolicy.ok": "",
  "app.offers.price.course.price.tooltip": "",
  "app.password.renew.title": "Neues Passwort festlegen",
  "app.password.renew.toLogin": "Zurück zum Login",
  "app.password.renew.text":
    "Aus Sicherheitsgründen ist es notwendig, Dein Passwort regelmäßig zu aktualisieren. Bitte erstelle ein neues Passwort.",
  "app.password.renew.expire.title": "Dein Passwort läuft bald ab",
  "app.password.renew.expire.text":
    "Aus Sicherheitsgründen ist es notwendig, Dein Passwort regelmäßig zu aktualisieren.",
  "app.password.renew.expire.changePassword": "Jetzt ändern",
  "app.password.renew.expire.ignore": "Später",
};
