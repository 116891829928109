import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import {
  Alert,
  Col,
  FormLabel,
  FormGroup,
  FormText,
  Row,
} from "react-bootstrap";
import { SaveAppointmentButton } from "./SaveAppointment";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import { ImgWrapper, Select } from "../Booking/AppointmentBookingForm";
import { getWeekDay, dateFormatter } from "../../utils/formatDate";
import { withLanguage } from "../../context/LanguageContext/withLanguage";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 15px;
  width: calc(100% + 15px);
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const Title = styled("div")`
  display: block;
  font-size: 16px;
  font-weight: bold;
`;

const ButtonContent = styled("div")`
  padding: 30px 0;
`;

/* const CustomTextArea = styled("textarea")`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`; */

class UpdateAppointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null,
      therapist: null,
      offer: null,
      room: null,
      timeSlot: null,
      timeSlotValidationState: null,
      appointmentId: this.props.appointmentId,
      info: this.props.info,
      appointmentUpdated: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.appointmentUpdated = this.appointmentUpdated.bind(this);
    this.onError = this.onError.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { value } = target;

    this.setState({ [name]: value });
  }

  appointmentUpdated() {
    this.setState({ appointmentUpdated: true });
    setTimeout(() => {
      this.setState({ appointmentUpdated: false });
    }, 3000);
  }

  validate() {
    let error = false;

    if (!this.state.timeSlot) {
      this.setState({ timeSlotValidationState: "error" });
      error = true;
    } else {
      this.setState({ timeSlotValidationState: "success" });
    }

    return !error;
  }

  getDateForOutput() {
    return `${this.props.intl.formatMessage({
      id: getWeekDay(this.state.date, true),
    })} ${dateFormatter(
      new Date(this.state.date),
      this.props.languageContext.language
    )}`;
  }

  getDropdownWithTimeSlots() {
    const children = [];
    const aSlots = this.state.offer.slots;

    if (aSlots.length > 0) {
      aSlots.forEach((timeSlot, index) => {
        children.push(
          <option
            key={index}
            value={`${timeSlot.timeStart}-${timeSlot.timeEnd}`}
            className={timeSlot.guestState}
          >
            {`${timeSlot.timeStart.substr(0, 5)} - ${timeSlot.timeEnd.substr(
              0,
              5
            )}${this.props.intl.formatMessage({ id: "app.global.clock" })}`}
          </option>
        );
      });
    } else {
      children.push(
        <option value="no-slots-available">
          <FormattedMessage id="app.appointments.editAppointment.noTimeSlots" />
        </option>
      );
    }

    return (
      <Select
        className="form-control"
        name="timeSlot"
        onChange={this.handleInputChange}
        value={this.state.timeSlot}
      >
        {children}
      </Select>
    );
  }

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError(error, errorTextTranslationKey) {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  UNSAFE_componentWillMount() {
    const appointment = this.props.data.node;

    const { date } = appointment;
    this.setState({ date });

    const { therapist } = appointment.eventTherapist;
    this.setState({ therapist });

    const { room } = appointment;
    this.setState({ room });

    /** get current offer with timeSlots * */
    const offer = appointment.eventTherapist.offers.filter(
      (offerContainer) =>
        offerContainer.offer.id === appointment.therapyOffer.id
    )[0];
    this.setState({ offer });

    const timeSlot = `${appointment.timeStart}-${appointment.timeEnd}`;
    this.setState({ timeSlot });
  }

  render() {
    return (
      <ScrollableDiv>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Content>
          {this.state.appointmentUpdated ? (
            <Alert variant="success">
              {" "}
              <p>
                {" "}
                <FormattedMessage id="app.appointments.updated" />{" "}
              </p>{" "}
            </Alert>
          ) : (
            ""
          )}
          <br />
          <Row>
            <Col xs={12}>
              <ImgWrapper>
                {this.state.therapist ? (
                  <img
                    src={JSON.parse(this.state.therapist.photo)}
                    alt={this.state.therapist.displayName}
                  />
                ) : (
                  ""
                )}
              </ImgWrapper>
              <Title>
                <FormattedMessage id="app.appointments.therapist" />
              </Title>
              <span className="text-muted">
                {this.state.therapist ? (
                  this.state.therapist.displayName
                ) : (
                  <br />
                )}
              </span>
              <Title>
                <FormattedMessage id="app.appointments.roomInfo" />
              </Title>
              <span className="text-muted">
                {this.state.room ? this.state.room.name : <br />}
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={6}>
              <FormGroup>
                <FormLabel>
                  <Title>
                    <FormattedMessage id="app.offers.date.date" />:
                  </Title>
                </FormLabel>
                {this.state.date ? (
                  <input
                    className="form-control"
                    disabled
                    value={this.getDateForOutput()}
                  />
                ) : (
                  <br />
                )}
              </FormGroup>
            </Col>
            <Col xs={12} sm={6}>
              <FormGroup validationState={this.state.timeSlotValidationState}>
                <FormLabel>
                  <Title>
                    <FormattedMessage id="app.offers.date.time" />:
                  </Title>
                </FormLabel>
                {this.getDropdownWithTimeSlots()}
                {this.state.timeSlotValidationState === "error" ? (
                  <FormText>
                    {" "}
                    <FormattedMessage id="app.appointments.selectTimeSlot" />{" "}
                  </FormText>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12}>
              <FormGroup>
                <FormLabel>
                  <Title>
                    <FormattedMessage id="app.offers.info" />:
                  </Title>
                </FormLabel>
                <CustomTextArea
                  name="info"
                  className="form-control"
                  value={this.state.info}
                  onChange={this.handleInputChange}
                  rows={4}
                />
              </FormGroup>
            </Col>
          </Row> */}
          <ButtonContent>
            <SaveAppointmentButton
              onValidate={this.validate}
              info={this.state.info}
              timeSlot={this.state.timeSlot}
              appointmentId={this.state.appointmentId}
              onFinish={this.appointmentUpdated}
              onError={this.onError}
            />
          </ButtonContent>
        </Content>
      </ScrollableDiv>
    );
  }
}

export default withLanguage(injectIntl(UpdateAppointment));
