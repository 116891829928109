import React, { Component, createElement } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { differenceInMilliseconds } from "date-fns";
import styled from "styled-components";
import { Col, Row, FormGroup } from "react-bootstrap";
import Button from "../CustomBootstrap/Button";
import CancelAppointment from "./CancelAppointment";
import CancelCourse from "./CancelCourse";
import { ImgWrapper, Title } from "../Booking/AppointmentBookingForm";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import CancelReservation from "./CancelReservation";
import JoinOnlineEventButton from "../JoinOnlineEventButton/JoinOnlineEventButton";
import { nl2br } from "../../utils";
import { getWeekDay } from "../../utils/formatDate";
import {
  getDateFromISODateAndTime,
  isEditableOrCancelable,
} from "../../utils/dateHelper";
import { DateFormatter } from "../../globalComponents/DateFormatter";
import "./Appointment.scss";

const ScrollableDiv = styled("div")`
  height: 100%;
  overflow-y: scroll;
  padding: 0 30px 15px;
  width: calc(100% + 15px);
`;

const Content = styled("div")`
  padding: 0 0 15px;
  text-align: left;
`;

const AppointmentRow = ({
  title = null,
  content = null,
  image = null,
  title2 = null,
  content2 = null,
}) => (
  <Row>
    <Col xs={12} className="pb-3">
      <FormGroup>
        {image && <ImgWrapper>{image}</ImgWrapper>}
        {title && (
          <Title>
            <FormattedMessage id={title} />
          </Title>
        )}
        {content && <span className="text-muted">{content}</span>}
        {title2 && (
          <Title>
            <FormattedMessage id={title2} />
          </Title>
        )}
        {content2 && <span className="text-muted">{content2}</span>}
      </FormGroup>
    </Col>
  </Row>
);

class Appointment extends Component {
  constructor(props) {
    super(props);

    this.state = { canceled: false };
  }

  cancel = () => {
    this.setState({ canceled: true });
    this.props.onDelete();
  };

  /**
   * opens modal box with error message
   *
   * @param error: returned from api
   * @param errorTextTranslationKey: returned form parseError
   */
  onError = (error, errorTextTranslationKey) => {
    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.node.id !== this.props.data.node.id) {
      this.setState({
        canceled: false,
      });
    }
  }

  notEnded = () =>
    differenceInMilliseconds(
      getDateFromISODateAndTime(
        this.props.data.node.date,
        this.props.data.node.timeEnd
      ),
      new Date()
    ) > 0;

  isCourse = () =>
    this.props.data.node.eventTherapist.roomEvent &&
    this.props.data.node.eventTherapist.roomEvent.type === "COURSE";

  isReservation = () =>
    this.props.data.node.__typename === "EventGuestReservation";

  getDates = () => {
    if (this.isCourse()) {
      return {
        startDate: this.props.data.node.eventTherapist.roomEvent.dateBegin,
        currentId: this.props.data.node.eventTherapist.roomEvent.id,
        startTime: this.props.data.node.timeStart,
      };
    }
    return {
      startDate: this.props.data.node.date,
      currentId: this.props.data.node.id,
      startTime: this.props.data.node.timeStart,
    };
  };

  renderCanceled = () => (
    <VerticallyAlignedMiddle height={window.innerHeight / 2}>
      <strong>
        <span className="large">
          <FormattedMessage
            id={
              this.isCourse()
                ? "app.appointments.courseCanceled"
                : "app.appointments.appointmentCanceled"
            }
          />
        </span>
      </strong>
    </VerticallyAlignedMiddle>
  );

  renderOnlineEventButton = () => {
    if (!(this.props.data.node.eventTherapist.isOnline && this.notEnded()))
      return null;
    return (
      <JoinOnlineEventButton
        style={{ marginBottom: "10px" }}
        title={
          this.props.data.node.eventTherapist.eventTherapistOnline.canGuestJoin
            ? this.props.intl.formatMessage({
                id: "app.home.widget.nextDate.joinOnlineEventButton.hintCanJoin",
              })
            : this.props.intl.formatMessage({
                id: "app.home.widget.nextDate.joinOnlineEventButton.hintCantJoin",
              })
        }
        variant="primary"
        size="lg"
        canJoin={
          this.props.data.node.eventTherapist.eventTherapistOnline.canGuestJoin
        }
        eventTherapistOnline={
          this.props.data.node.eventTherapist.eventTherapistOnline
        }
      >
        <FormattedMessage id="app.home.widget.nextDate.joinOnlineEventButton" />
      </JoinOnlineEventButton>
    );
  };

  renderEditButton = (startDate, startTime) => {
    if (
      !(
        isEditableOrCancelable(
          this.props.dateEditableUntil,
          startDate,
          startTime
        ) &&
        !this.isCourse() &&
        !this.isReservation()
      )
    )
      return null;

    return (
      <div className="d-grid pb-2">
        <Button
          onClick={this.props.onEditAppointment}
          variant="primary"
          size="lg"
        >
          <FormattedMessage id="app.appointments.editAppointment" />
        </Button>
      </div>
    );
  };

  renderAppointmentRows = () => {
    const paymentMethod = this.props.data.node.reservationPayment
      ? this.props.data.node.reservationPayment
      : this.props.data.node.payment;

    return (
      <>
        <AppointmentRow
          image={
            this.props.data.node.eventTherapist.therapist ? (
              <img
                src={JSON.parse(
                  this.props.data.node.eventTherapist.therapist.photo
                )}
                alt={this.props.data.node.eventTherapist.therapist.name}
              />
            ) : null
          }
          title="app.appointments.therapist"
          content={
            this.props.data.node.eventTherapist.therapist ? (
              this.props.data.node.eventTherapist.therapist.displayName
            ) : (
              <br />
            )
          }
          title2="app.appointments.roomInfo"
          content2={
            this.props.data.node.room ? this.props.data.node.room.name : <br />
          }
        />
        {/* this.props.data.node.info && (
          <AppointmentRow
            title="app.appointments.guestInfo"
            content={this.props.data.node.info}
          />
        ) */}
        <AppointmentRow
          title={
            this.isCourse()
              ? "app.appointments.roomInfo.COURSE"
              : this.isReservation()
              ? "app.appointments.reservation"
              : "app.appointments.appointment"
          }
          content={
            <>
              <FormattedMessage id={getWeekDay(this.props.data.node.date)} />{" "}
              <DateFormatter
                date={new Date(this.props.data.node.date)}
                type="DATE"
              />{" "}
              <DateFormatter
                date={
                  new Date(
                    `${this.props.data.node.date} ${this.props.data.node.timeStart}`
                  )
                }
                type="TIME"
              />
              {" - "}
              <DateFormatter
                date={
                  new Date(
                    `${this.props.data.node.date} ${this.props.data.node.timeEnd}`
                  )
                }
                type="TIME"
              />
            </>
          }
        />
        {paymentMethod !== "SUBVENTION" && (
          <AppointmentRow
            title="app.appointments.paymentMethod"
            content={
              <FormattedMessage
                id={`app.appointments.paymentMethod.${paymentMethod}`}
              />
            }
          />
        )}
        {this.props.data.node.binding && (
          <AppointmentRow
            title="app.appointments.binding"
            content={
              <FormattedMessage
                id={`app.appointments.binding.${this.props.data.node.binding}`}
              />
            }
          />
        )}
        {this.props.data.node.therapyOffer.emailNote && (
          <AppointmentRow
            title="app.appointments.note"
            content={createElement("span", {
              dangerouslySetInnerHTML: {
                __html: nl2br(this.props.data.node.therapyOffer.emailNote),
              },
            })}
          />
        )}
      </>
    );
  };

  renderCancelEvent = (currentId, startDate, startTime) => {
    if (
      differenceInMilliseconds(
        new Date(this.props.data.node.date),
        new Date()
      ) <= 0
    )
      return null;

    if (
      !isEditableOrCancelable(
        this.props.dateCancelableUntil,
        startDate,
        startTime
      )
    )
      return null;

    if (this.isCourse())
      return (
        <CancelCourse
          id={currentId}
          onDelete={this.cancel}
          onError={this.onError}
        />
      );

    if (this.isReservation())
      return (
        <CancelReservation
          id={currentId}
          onDelete={this.cancel}
          onError={this.onError}
        />
      );

    return (
      <CancelAppointment
        id={currentId}
        onDelete={this.cancel}
        onError={this.onError}
      />
    );
  };

  render() {
    if (this.state.canceled) return this.renderCanceled();

    const { startDate, startTime, currentId } = this.getDates();

    return (
      <ScrollableDiv className="appointments">
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <Content>
          <br />
          {this.renderAppointmentRows()}
          {this.renderOnlineEventButton()}
          {this.renderEditButton(startDate, startTime)}
          {this.renderCancelEvent(currentId, startDate, startTime)}
        </Content>
      </ScrollableDiv>
    );
  }
}

export default injectIntl(Appointment);
