import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Alert,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Col,
  FormCheck,
  InputGroup,
  FormText,
  FormSelect,
} from "react-bootstrap";
import styled from "styled-components";
import "./Question.scss";

const QuestionForm = styled(FormGroup)`
  FormControl {
    margin-right: 20px;
  }
  FormText {
    display: block;
    font-size: 0.75em;
  }
  .question-input Button {
    margin-top: 0;
  }
  .question-input:first-child {
    margin: auto;
    width: 90% !important;
  }
  &.has-success .btn-default {
    border-color: #3c763d !important;
  }
  &.has-success .btn-default.active,
  &.has-success .spinner button {
    color: #3c763d !important;
    background-color: #dff0d8 !important;
    border-color: #3c763d !important;
  }
  &.has-error .btn-default {
    border-color: #a94442 !important;
  }
  &.has-error .btn-default.active,
  &.has-error .spinner button {
    color: #a94442 !important;
    background-color: #f9f2f4 !important;
    border-color: #a94442 !important;
  }

  .input-group .dropdown {
    display: block;
  }
`;

const CustomFormControl = styled(FormControl)`
  border-color: ${(props) =>
    !props.bordercolor ? `${props.theme.btnBg} !important` : ""};
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

const CustomSelect = styled(FormSelect)`
  border-color: ${(props) =>
    !props.bordercolor ? `${props.theme.btnBg} !important` : ""};
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
  option:focus,
  option:active,
  option:visited,
  option:checked {
    background-color: ${(props) =>
      !props.bordercolor ? `${props.theme.btnBg} !important` : ""};
    color: ${(props) =>
      !props.bordercolor ? `${props.theme.btnText} !important` : ""};
  }
`;

const CustomFormCheck = styled(FormCheck)`
  .form-check-input:checked {
    background-color: ${(props) =>
      !props.bordercolor ? `${props.theme.btnBg} !important` : ""};
    border-color: ${(props) =>
      !props.bordercolor ? `${props.theme.btnBg} !important` : ""};
  }
`;

const TYPES = {
  DROPDOWN: 1,
  TEXT: 2,
  LONGTEXT: 3,
  CHECKBOXES: 4,
  RADIOBUTTONS: 5,
  NUMBER: 6,
  SLIDER: 7,
  YES_NO: 8,
  SPINNER: 9,
  ZONES_UPPER: 10,
  ZONES_FULL: 11,
};

class Question extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.renderFormControl = this.renderFormControl.bind(this);

    this.state = {
      valid: props.question.requiredAnswers === 0,
      question: props.question,
      answer: null,
      changed: false,
      freeTextVal: "",
    };
  }

  getValidationState() {
    if (this.state.changed === false) {
      if (this.state.question.requiredAnswers > 0) {
        return null;
      }
    }

    if (this.state.valid) {
      return "success";
    }
    return "error";
  }

  handleChange(e) {
    let answer = null;
    let valid = false;
    const { question } = this.state;

    switch (question.display.type) {
      case TYPES.DROPDOWN: {
        answer = [];
        const { options } = e.target;
        for (let i = 0, iLen = options.length; i < iLen; i++) {
          if (options[i].selected && `${options[i].value}`.length > 0) {
            answer.push(options[i].value);
          }
        }
        valid =
          answer !== null && answer.length !== undefined
            ? answer.length >= this.state.question.requiredAnswers
            : false;
        break;
      }
      case TYPES.CHECKBOXES:
        answer = this.state.answer === null ? [] : this.state.answer;
        if (e.target.checked) {
          if (`${e.target.value}`.length > 0) {
            answer.push(e.target.value);
          }
          if (e.target.prevValue !== undefined) {
            answer = answer.filter((a) => a !== e.target.prevValue);
          }
        } else {
          answer = answer.filter((a) => a !== e.target.value);
        }

        valid = answer.length >= this.state.question.requiredAnswers;
        break;
      case TYPES.RADIOBUTTONS:
        answer = e.target.value;
        valid = true;
        break;
      case TYPES.NUMBER: {
        const val = e.target.value.replace(/[^0-9]/g, "");
        answer = val === "" ? null : parseInt(val, 10);
        valid =
          answer !== null &&
          `${answer}`.length >= this.state.question.requiredAnswers;
        if (
          answer !== null &&
          question.display.options.min !== undefined &&
          answer < question.display.options.min
        ) {
          valid = false;
        }
        if (
          answer !== null &&
          question.display.options.max !== undefined &&
          answer > question.display.options.max
        ) {
          answer = parseInt(question.display.options.max, 10);
          valid = false;
        }
        break;
      }
      case TYPES.YES_NO:
        answer = e;
        valid = true;
        break;
      case TYPES.TEXT:
      case TYPES.LONGTEXT:
        answer = e.target.value !== undefined ? e.target.value : "";
        valid = answer.length >= this.state.question.requiredAnswers;
        break;
      default:
    }
    this.setState((prevState) => ({
      answer,
      valid,
      changed: true,
      question: { valid, ...prevState.question },
    }));
    this.props.onUpdate(this.state.question.id, answer, valid);
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.question.id !== this.state.question.id) {
      this.setState({
        question: this.props.question,
        valid: this.props.question.requiredAnswers === 0,
        changed: false,
        answer: null,
      });
    }
  }

  renderFormControl(question) {
    switch (question.display.type) {
      case TYPES.DROPDOWN: {
        // BUG: question.display.options.multi_select kommt vom Backend immer als "1"
        // Multiselect daher deaktiviert
        const selAnswer = "";
        /* question.display.options.multi_select !== undefined &&
          question.display.options.multi_select
            ? []
            : ""; */
        return (
          <InputGroup bsClass="input-group dropdown">
            <CustomSelect
              className="form-select"
              required={question.requiredAnswers > 0}
              multiple={
                false
                /* question.display.options.multi_select !== undefined &&
                question.display.options.multi_select */
              }
              bsClass="form-control input-small"
              as="select"
              value={this.state.answer === null ? selAnswer : this.state.answer}
              onChange={this.handleChange}
            >
              {selAnswer === "" ? (
                <option key={0} value="">
                  {this.props.intl.formatMessage({
                    id: "app.survey.questionnaire.question.dropdown.placeholder",
                  })}
                </option>
              ) : (
                ""
              )}
              {question.display.answers.map((answer) => (
                <option key={answer.id} value={answer.value}>
                  {answer.title}
                </option>
              ))}
            </CustomSelect>
            {question.display.options.unit !== undefined ? (
              <InputGroup.Text>{question.display.options.unit}</InputGroup.Text>
            ) : (
              ""
            )}
          </InputGroup>
        );
      }
      case TYPES.TEXT:
        return (
          <CustomFormControl
            required={question.requiredAnswers > 0}
            type="text"
            value={this.state.answer === null ? "" : this.state.answer}
            placeholder={this.props.intl.formatMessage({
              id: "app.survey.questionnaire.question.text.placeholder",
            })}
            onChange={this.handleChange}
          />
        );
      case TYPES.LONGTEXT:
        return (
          <CustomFormControl
            className="form-control"
            required={question.requiredAnswers > 0}
            size="lg"
            as="textarea"
            value={this.state.answer === null ? "" : this.state.answer}
            rows={
              question.display.options.rows !== undefined
                ? question.display.options.rows * 1
                : 5
            }
            placeholder={this.props.intl.formatMessage({
              id: "app.survey.questionnaire.question.longtext.placeholder",
            })}
            onChange={this.handleChange}
          />
        );
      case TYPES.CHECKBOXES:
        return (
          <FormGroup>
            {question.display.answers.map((answer) => (
              <CustomFormCheck
                key={answer.id}
                id={`checkbox_${answer.id}`}
                value={answer.value}
                onChange={this.handleChange}
                label={answer.title}
              />
            ))}
            {question.display.options.add_free_answer ? (
              <CustomFormCheck
                disabled
                checked={this.state.freeTextVal.length > 0}
                value={this.state.freeTextVal}
                onChange={this.handleChange}
              >
                <FormControl
                  size="sm"
                  type="text"
                  value={this.state.freeTextVal}
                  placeholder={question.display.options.free_answer_label}
                  onChange={(e) => {
                    this.setState({ freeTextVal: e.target.value });
                    const ev = { ...e };
                    ev.target.checked = true;
                    ev.target.prevValue = this.state.freeTextVal;
                    this.handleChange(ev);
                  }}
                />
              </CustomFormCheck>
            ) : (
              ""
            )}
          </FormGroup>
        );
      case TYPES.RADIOBUTTONS:
        return (
          <FormGroup>
            {question.display.answers.map((answer) => (
              <CustomFormCheck
                type="radio"
                key={answer.id}
                id={`radio_${answer.id}`}
                name={`quest-${this.state.question.id}`}
                value={answer.value}
                onClick={this.handleChange}
                label={answer.title}
                // className="d-flex pl-1"
              />
            ))}
          </FormGroup>
        );
      case TYPES.NUMBER:
        return (
          <InputGroup bsClass="input-group__numbers">
            <CustomFormControl
              pattern="^[0-9]*$"
              required={question.requiredAnswers > 0}
              size="small"
              type="text"
              value={this.state.answer === null ? "" : this.state.answer}
              placeholder={this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.number.placeholder",
              })}
              onChange={this.handleChange}
            />{" "}
            {question.display.options.unit !== undefined ? (
              <InputGroup.Text>{question.display.options.unit}</InputGroup.Text>
            ) : (
              ""
            )}
          </InputGroup>
        );
      case TYPES.YES_NO:
        return (
          <FormGroup>
            <CustomFormCheck
              type="radio"
              name={`yes-no-${this.state.question.id}`}
              key={`yes-no-${this.state.question.id}-yes`}
              id={`yes-no-${this.state.question.id}-yes`}
              label={this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.yes",
              })}
              value={1}
              onClick={() => this.handleChange(1)}
            />
            <CustomFormCheck
              type="radio"
              name={`yes-no-${this.state.question.id}`}
              key={`yes-no-${this.state.question.id}-no`}
              id={`yes-no-${this.state.question.id}-no`}
              label={this.props.intl.formatMessage({
                id: "app.survey.questionnaire.question.no",
              })}
              value={0}
              onClick={() => this.handleChange(0)}
            />
          </FormGroup>
        );
      default:
        return (
          <p>
            <FormattedMessage id="app.survey.questionnaire.question.unknownType" />
          </p>
        );
    }
  }

  render() {
    if (this.state.question === undefined || this.state.question === null) {
      return null;
    }

    let errorMessage = null;

    if (this.state.valid === false && this.state.changed === true) {
      errorMessage = "app.survey.questionnaire.error.required";
    }

    return (
      <QuestionForm
        controlId={`form-control-${this.state.question.id}`}
        htmlValidationState={this.getValidationState()}
      >
        <Row style={{ paddingBottom: "25px" }}>
          <Col
            className="question-label"
            xs={12}
            md={this.state.question.singleRow === true ? 12 : 6}
            style={{ paddingBottom: "6px" }}
          >
            <FormLabel style={{ width: "100%", marginBottom: "0.2rem" }}>
              {this.state.question.title}
            </FormLabel>
            {React.createElement(FormText, {
              dangerouslySetInnerHTML: { __html: this.state.question.text },
            })}
            {errorMessage !== null && (
              <Alert
                variant="danger"
                style={{ marginTop: "10px", marginBottom: "5px" }}
              >
                {React.createElement("p", {
                  dangerouslySetInnerHTML: {
                    __html: this.props.intl.formatMessage({ id: errorMessage }),
                  },
                })}
              </Alert>
            )}
          </Col>
          <Col
            className="question-input"
            xs={12}
            md={this.state.question.singleRow === true ? 12 : 6}
          >
            {this.renderFormControl(this.state.question)}
            <FormControl.Feedback />
          </Col>
        </Row>
      </QuestionForm>
    );
  }
}

export default injectIntl(Question);
