import React from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
// import { FaPlus, FaMinus } from "react-icons/fa";
import Icon from "react-icon-base";

const FaPlus = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m35.9 16.4v4.3q0 0.9-0.6 1.5t-1.5 0.7h-9.3v9.2q0 0.9-0.6 1.6t-1.5 0.6h-4.3q-0.9 0-1.5-0.6t-0.7-1.6v-9.2h-9.3q-0.9 0-1.5-0.7t-0.6-1.5v-4.3q0-0.9 0.6-1.5t1.5-0.6h9.3v-9.3q0-0.9 0.7-1.5t1.5-0.6h4.3q0.9 0 1.5 0.6t0.6 1.5v9.3h9.3q0.9 0 1.5 0.6t0.6 1.5z" />
    </g>
  </Icon>
);

const FaMinus = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m35.9 16.4v4.3q0 0.9-0.6 1.5t-1.5 0.7h-27.2q-0.8 0-1.5-0.7t-0.6-1.5v-4.3q0-0.9 0.6-1.5t1.5-0.6h27.2q0.9 0 1.5 0.6t0.6 1.5z" />
    </g>
  </Icon>
);

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };

    this.add = this.add.bind(this);
    this.minus = this.minus.bind(this);
    this.change = this.change.bind(this);
  }

  add() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const val = this.state.value * 1 + 1;
    this.setState({ value: val });
    this.props.onChange(val);
  }

  minus() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const val = this.state.value * 1 - 1;
    this.setState({ value: val });
    this.props.onChange(val);
  }

  change(e) {
    const val = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ value: val });
    this.props.onChange(val);
  }

  componentDidUpdate() {
    if (this.state.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const rad = this.props.unit !== undefined ? "0px" : "";

    return (
      <InputGroup className={this.props.className}>
        <InputGroup.Text>
          <Button size={this.props.size} onClick={this.minus}>
            <FaMinus />
          </Button>
        </InputGroup.Text>
        <FormControl
          readOnly={this.props.readOnly}
          value={this.state.value}
          size={this.props.size}
          placeholder={this.props.placeholder}
          type="text"
          onChange={this.change}
        />
        <InputGroup.Text>
          <Button
            style={{ borderRadius: rad }}
            size={this.props.size}
            onClick={this.add}
          >
            <FaPlus />
          </Button>
        </InputGroup.Text>
        {this.props.unit !== undefined ? (
          <InputGroup.Text>{this.props.unit}</InputGroup.Text>
        ) : (
          ""
        )}
      </InputGroup>
    );
  }
}

export default Spinner;
