import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import base64 from "base-64";
import {
  Col,
  FormLabel,
  Form,
  FormGroup,
  Row,
  FormControl,
} from "react-bootstrap";
import Button from "../CustomBootstrap/Button";
import { theme } from "../../globalComponents/Theme";
import { PaymentSelect } from "./PaymentSelect";
import VerticallyAlignedMiddle from "../VerticallyAlignedMiddle";
import AlertModalBox from "../../globalComponents/AlertModalBox";
import ModalBox from "../../globalComponents/ModalBox";
import { parseError } from "../../globalComponents/ErrorMessageHandler";
import Conf from "../../Conf";
import { isBookable } from "./BookingHandler";
import Questionnaire from "../Survey/Questionnaire";
import InvokeCoupon from "../Coupon/Invoke";
import { isSameDay } from "../../utils/formatDate";
import { DayPickerInput } from "../../globalComponents/DayPickerInput";
// import { DateFormatter } from "../../globalComponents/DateFormatter";

import {
  BOOK_DATE_MUTATION,
  RESERVATION_MUTATION,
  VOTE_MUTATION,
} from "./AppointmentBookingForm.graphql";
import { CALENDAR_COMPONENT_QUERY } from "../Calendar/CalendarComponent.graphql";
import budgetLeftError from "../../utils/budgetLeftError";

/* const CustomTextarea = styled("textarea")`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`; */

const CustomInput = styled(FormControl)`
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
  }
`;

export const Content = styled("div")`
  text-align: left;
`;

export const ImgWrapper = styled("div")`
  float: left;
  padding-right: 15px;
  width: 90px;
  height: 100px;

  img {
    width: 100%;
    height: auto;
    max-height: 100px;
  }
`;

export const Title = styled("div")`
  display: block;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
`;

export const TitleOffer = styled("div")`
  display: block;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0 0 15px;
`;

const OptionTextWrapper = styled("span")`
  padding-left: 15px;
`;

export const ButtonContent = styled("div")`
  padding: 10px 0 0;
`;

export const Select = styled(Form.Select)`
  text-overflow: ellipsis;
  @media screen and (max-width: 1025px) {
    font-size: 16px !important;
    padding: 5px 12px !important;
  }
`;

class AppointmentBooking extends Component {
  constructor(props) {
    super(props);

    /** radix is needed when using parseInt in general */
    this.iRadix = 10;

    this.state = {
      therapistName: null,
      therapistPhoto: null,
      date: null,
      eventDayList: null,
      event: null,
      eventDay: null,
      eventRoomName: null,
      eventOffer: null,
      eventOfferSlot: null,
      eventOfferPrice: null,
      eventOfferSubvention: null,
      eventOfferList: null,
      paymentMethod: "SUBVENTION",
      // textAreaValue: "",
      bookSuccess: null,
      showMessage: false,
      showLoader: false,
      isReservationFormVisible: false,
      isBinding: false,
      binding: null,
      survey: null,
      surveyCode: null,
      enabledDays: [],
      contactNumberValue: "",
      validated: false,
      contactNumberRequired: false,
    };

    // Debug survey
    /* this.state = {
      ...this.state,
      ...{
        bookSuccess: true,
        survey: {
          id: "5",
          token: "xxx", // changeme
          dateBegin: "2022-05-25",
          dateEnd: "2030-05-25",
          title: "Testbogen",
          text: "<p>Hallo!</p>\n<p>Um unseren Service zu verbessern, ben&ouml;tigen wir Dein Feedback!</p>\n<p>Vielen Dank f&uuml;r Deine Unterst&uuml;tzung!</p>",
          finish: "<p>Vielen Dank f&uuml;r Deine Teilnahme!</p>",
          predictedTime: 5,
          logo: null,
          public: true,
          __typename: "Survey",
        },
        surveyCode: "xxx", // changeme
        showMessage: false,
        showLoader: false,
      },
    }; */
    // ---

    this.parseError = parseError.bind(this);
    this.showReservationForm = this.showReservationForm.bind(this);
    this.initPaymentMethod = this.initPaymentMethod.bind(this);
    this.disabledDays = this.disabledDays.bind(this);

    this._modalBox = null;
    this._paymentSelectRefresh = null;

    this.bindings = [
      "RESERVATION_ONE_HOUR",
      "RESERVATION_FOUR_HOURS",
      "ONE_DAY",
    ];

    this.initState = this.initState.bind(this);
  }

  /**
   * converts date as string of format: 2017-04-01
   * into javascript Date-Object
   */
  static convertStringDateToObjectDate(sDate) {
    const aDate = sDate.split("-");
    const day = aDate[2];
    const month = aDate[1] - 1;
    const year = aDate[0];

    return new Date(year, month, day);
  }

  updatePaymentMethodBy(sPaymentMethod) {
    this.setState({ paymentMethod: sPaymentMethod });
  }

  initPaymentMethod(methods) {
    if (methods.includes("SUBVENTION")) {
      this.setState({ paymentMethod: "SUBVENTION" });
      return;
    }
    if (methods.includes("CREDIT")) {
      this.setState({ paymentMethod: "CREDIT" });
      return;
    }
    if (methods.includes("DIRECT_DEBIT")) {
      this.setState({ paymentMethod: "DIRECT_DEBIT" });
      return;
    }
    if (methods.includes("CASH")) {
      this.setState({ paymentMethod: "CASH" });
    }
  }

  /* updateTextAreaValue(event) {
    this.setState({ textAreaValue: event.target.value });
  } */

  updateContactNumberValue(event) {
    const invalid = /[A-Za-z]/.test(event.target.value);

    if (!invalid) {
      this.setState({ contactNumberValue: event.target.value });
    }
  }

  /**
   * updates states of event and
   * eventRoomName (if existing)
   */
  updateEvent(event) {
    let eventRoomName = null;
    if (event.room) eventRoomName = event.room.name;

    this.setState({ event, eventRoomName });
  }

  /**
   * updates therapist name and photo
   */
  updateTherapistBy(event) {
    const { therapist } = event;

    if (therapist === null)
      this.setState({ therapistName: null, therapistPhoto: null });
    else
      this.setState({
        therapistName: therapist.displayName,
        therapistPhoto: therapist.photo,
      });
  }

  showReservationForm() {
    this.setState({ isReservationFormVisible: true });
  }

  async submitForm(event) {
    event.preventDefault();
    event.stopPropagation();

    if (
      this.state.contactNumberRequired &&
      this.state.contactNumberValue === ""
    ) {
      this.setState({ validated: true });
      return;
    }

    if (this.state.eventOfferSlots?.guestState === "BOOKED") {
      this.props.history.push(`/appointments`);
      return;
    }

    if (!this.isEmployeeInBudget()) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({ id: "app.error.lowbudget" })
      );
      return;
    }

    if (
      !isBookable(
        this.props.dateEditableUntil,
        this.state.eventDay.date,
        this.state.eventOfferSlot.timeStart
      )
    ) {
      this._alertModal.open(
        this.props.intl.formatMessage({ id: "app.global.error" }),
        this.props.intl.formatMessage({
          id: "app.offers.book.error.bookLimitExceeded",
        })
      );
      return;
    }

    if (
      this.state.eventOfferSlot.guestState === "NOT_AVAILABLE" &&
      this.isReservationEnabled()
    ) {
      this._modalBox.open(
        this.props.intl.formatMessage({ id: "app.offers.date.reserve" }),
        this.props.intl.formatMessage({ id: "app.offers.date.reserve.text" }),
        this.showReservationForm /* callback */
      );
    } else {
      this.setState({ showLoader: true });

      try {
        const bookDateResult = await this.props.bookDate({
          variables: {
            eventTherapistId: this.state.event.id,
            therapyOfferId: this.state.eventOffer.offer.id,
            timeStart: this.state.eventOfferSlot.timeStart,
            timeEnd: this.state.eventOfferSlot.timeEnd,
            paymentType: this.state.paymentMethod,
            info: this.state.textAreaValue,
            contactNumber: this.state.contactNumberValue,
          },
        });

        if (this.props.week) {
          await this.props.data.refetch();
        }

        if (bookDateResult.data.bookDate.preDateVoteLink !== null) {
          const voteResult = await this.props.vote({
            variables: {
              key: bookDateResult.data.bookDate.preDateVoteLink.voteKey,
            },
          });

          this.BookingSuccess(voteResult.data);
        } else {
          this.BookingSuccess(null);
        }
      } catch (error) {
        this.BookingError(error);
      }
    }
  }

  submitReservation(event) {
    event.preventDefault();

    this.setState({ showLoader: true });

    this.props
      .reservation({
        variables: {
          eventTherapistId: this.state.event.id,
          therapyOfferId: this.state.eventOffer.offer.id,
          timeStart: this.state.eventOfferSlot.timeStart,
          timeEnd: this.state.eventOfferSlot.timeEnd,
          payment: this.state.paymentMethod,
          binding: this.state.isBinding ? this.state.binding : "NO_BINDING",
        },
      })
      .then(() => {
        this.BookingSuccess(null);
      })
      .catch((error) => {
        this.BookingError(error);
      });
  }

  BookingSuccess(surveyData) {
    this.setState({
      bookSuccess: true,
      survey: surveyData?.vote?.survey ?? null,
      surveyCode: surveyData?.vote?.code ?? null,
      showMessage: surveyData == null,
      showLoader: false,
    });
  }

  BookingError(error) {
    const errorTextTranslationKey = this.parseError(
      error,
      Conf.errorContext.offerBookingError
    );

    this.setState({
      bookSuccess: false,
      showMessage: true,
      showLoader: false,
    });

    this._alertModal.open(
      this.props.intl.formatMessage({ id: "app.global.error" }),
      this.props.intl.formatMessage({ id: errorTextTranslationKey })
    );
  }

  /**
   *
   * @returns {{freeSlots: number, totalSlots: number}}
   */
  static getTotalAndFreeSlots(oEventDay, iOfferId) {
    let iAllFreeSlots = 0;
    let iAllTotalSlots = 0;

    oEventDay.events.forEach((oEvent) => {
      oEvent.offers.forEach((oOfferContainer) => {
        if (oOfferContainer.offer.id === iOfferId) {
          iAllFreeSlots += oOfferContainer.freeSlots;
          iAllTotalSlots += oOfferContainer.totalSlots;
        }
      });
    });

    return { freeSlots: iAllFreeSlots, totalSlots: iAllTotalSlots };
  }

  isEmployeeInBudget() {
    return (
      this.props.employeeBudgetLeft === null ||
      this.props.employeeBudgetLeft >= this.state.eventOfferSubvention
    );
  }

  /**
   * checks on current day all events with the same offer
   * whether current selected TimeSlot exists at least on more time
   */
  isCurrentTimeSlotAvailableOnOtherDates() {
    let timeSlotCounter = 0;

    if (this.state.eventDayList && this.state.eventOfferSlot) {
      const curEventDay = this.state.eventDay;
      const curEventId = this.state.event.id;
      const curOfferId = this.state.eventOffer.offer.id;
      const curTimeSlot = this.state.eventOfferSlot;

      curEventDay.events.forEach((event) => {
        event.offers.forEach((offerContainer) => {
          offerContainer.slots.forEach((slot) => {
            if (
              curOfferId === offerContainer.offer.id &&
              curEventId !== event.id &&
              curTimeSlot.timeStart === slot.timeStart &&
              curTimeSlot.timeEnd === slot.timeEnd
            ) {
              timeSlotCounter += 1;
            }
          });
        });
      });
    }

    return timeSlotCounter > 0;
  }

  getAllSlotsForCurrentOfferFromEvent(ev) {
    const slots = [];

    ev.offers.forEach((offer) => {
      if (offer.offer.id === this.state.eventOffer.offer.id) {
        const slot = {
          freeSlots: offer.freeSlots,
          totalSlots: offer.totalSlots,
        };
        slots.push(slot);
      }
    });

    return slots;
  }

  getHighlightningForEventDay(evDay) {
    const { date } = evDay;
    let slots = [];
    const evStates = [];

    evDay.events.forEach((ev) => {
      evStates.push(ev.guestState);
      const s = this.getAllSlotsForCurrentOfferFromEvent(ev);
      if (s.length > 0) slots = slots.concat(s);
    });

    let finalState = null;
    if (evStates.includes("BOOKED")) {
      finalState = "BOOKED";
    } else if (evStates.includes("RESERVED")) {
      finalState = "RESERVED";
    } else if (evStates.includes("NOT_AVAILABLE")) {
      /** on this point only NOT_AVAILABLE or AVAILABLE can be in the array
       *  to have state NOT_AVAILABLE as finalState, the array must not include anything but this state
       * */
      if (!evStates.includes("AVAILABLE")) {
        finalState = "NOT_AVAILABLE";
      }
    }
    if (finalState === null && evStates.includes("AVAILABLE")) {
      let sumFreeSlots = 0;
      let sumTotalSlots = 0;

      slots.forEach((slot) => {
        sumFreeSlots += slot.freeSlots;
        sumTotalSlots += slot.totalSlots;
      });

      finalState = sumFreeSlots / sumTotalSlots <= 0.3 ? "LOW" : "AVAILABLE";
    }

    return { date: new Date(date), state: finalState };
  }

  getHighlightedDates() {
    /** if nothing is set yet we cannot work with it */
    if (this.state.eventDayList === null || this.state.eventOffer === null)
      return null;

    const highlightedDays = [];
    this.state.eventDayList.forEach((evDay) => {
      highlightedDays.push(this.getHighlightningForEventDay(evDay));
    });

    let booked = highlightedDays.filter((day) => day.state === "BOOKED");

    let reserved = highlightedDays.filter((day) => day.state === "RESERVED");

    let available = highlightedDays.filter((day) => day.state === "AVAILABLE");

    let lowAvailable = highlightedDays.filter((day) => day.state === "LOW");

    let notAvailable = highlightedDays.filter(
      (day) => day.state === "NOT_AVAILABLE"
    );

    booked = booked.map((el) => el.date);
    reserved = reserved.map((el) => el.date);
    available = available.map((el) => el.date);
    lowAvailable = lowAvailable.map((el) => el.date);
    notAvailable = notAvailable.map((el) => el.date);

    return {
      booked,
      reserved,
      available,
      lowAvailable,
      notAvailable,
    };
  }

  /**
   *
   * @param aEventDayList
   * @param sDate (format: '29.04.2017')
   * @param iOfferId
   * @returns {boolean}
   */
  isDateDisabledDueToDisabledTimeSlots(aEventDayList, sDate, iOfferId) {
    const aTimeSlots = this.getAllTimeSlotsAtDateWithOfferId(
      aEventDayList,
      sDate,
      iOfferId
    );

    let bDateIsDisabled = true;

    aTimeSlots.forEach((oTimeSlot) => {
      if (isBookable(this.props.dateEditableUntil, sDate, oTimeSlot.timeStart))
        bDateIsDisabled = false;
    });

    return bDateIsDisabled;
  }

  /**
   *
   * @param aEventDayList
   * @param sDate (format: '2017-29-04')
   * @param iOfferId
   */
  getAllTimeSlotsAtDateWithOfferId(aEventDayList, sDate, iOfferId) {
    /** find event day with given date */
    const aEventDay = aEventDayList.filter((evDay) => evDay.date === sDate);

    if (aEventDay.length === 0)
      throw new Error(`Could not find an event day for given date: ${sDate}`);

    const oEventDay = aEventDay[0];

    return this.getAllTimeSlotsFromEventsWithSameOfferId(
      oEventDay.events,
      iOfferId
    );
  }

  /**
   * gets all time slots from given offer
   * across all events
   */
  getAllTimeSlotsFromEventsWithSameOfferId(aEvents, iOfferId) {
    const aTimeSlots = [];

    aEvents.forEach((event) => {
      event.offers.forEach((offerContainer) => {
        if (iOfferId === offerContainer.offer.id) {
          offerContainer.slots.forEach((slot) => {
            /** store event id to the timeSlot
             *  this is needed to match event by selecting time
             * */
            const adjustedSlot = { ...slot };
            adjustedSlot.eventId = event.id;

            aTimeSlots.push(adjustedSlot);
          });
        }
      });
    });

    return this.sortTimeSlotsASC(aTimeSlots);
  }

  /**
   * sort time ASC
   * e.g. [08:00, 09:30, 09:30, 10:00, 11:30]
   * */
  sortTimeSlotsASC(aTimeSlots) {
    aTimeSlots.sort((slotFirst, slotSecond) => {
      const curHour = slotFirst.timeStart.split(":")[0];
      const iCurHour = parseInt(curHour, this.iRadix);

      const nextHour = slotSecond.timeStart.split(":")[0];
      const iNextHour = parseInt(nextHour, this.iRadix);

      /** if iCurHour is smaller, sort it before nextHour */
      if (iCurHour < iNextHour) return -1;

      /** if iCurHour is bigger, sort it after nextHour */
      if (iCurHour > iNextHour) return 1;

      /** if iCurHour is equal to nextHour */
      if (iCurHour === iNextHour) {
        const curMinutes = slotFirst.timeStart.split(":")[1];
        const nextMinutes = slotSecond.timeStart.split(":")[1];

        /** if curMinutes are smaller, sort it before nextMinutes */
        if (curMinutes < nextMinutes) return -1;

        /** if curMinutes are bigger, sort it after nextMinutes */
        if (curMinutes > nextMinutes) return 1;
      }

      return 0;
    });

    return aTimeSlots;
  }

  isReservationEnabled() {
    if (this.props.reservationEnabled !== null)
      return this.props.reservationEnabled;

    return false;
  }

  getDropdownWithBindings() {
    return (
      <Select
        className="form-control"
        onChange={(index) => this.setState({ binding: index.target.value })}
      >
        {this.bindings.map((binding) => (
          <option key={binding} value={binding}>
            {this.props.intl.formatMessage({
              id: `app.offers.date.reserve.${binding}`,
            })}
          </option>
        ))}
      </Select>
    );
  }

  getDropdownWithTimeSlots() {
    const oCurEventDay = this.state.eventDay;
    const iCurOfferId = this.state.eventOffer.offer.id;
    const aTimeSlots = this.getAllTimeSlotsFromEventsWithSameOfferId(
      oCurEventDay.events,
      iCurOfferId
    );

    // const children = aTimeSlots.map((oTimeSlot, index) => (
    //   <option key={`option-${index}`} value={JSON.stringify(oTimeSlot)}>
    //     <DateFormatter time={oTimeSlot.timeStart} />
    //     {" - "}
    //     <DateFormatter time={oTimeSlot.timeEnd} />
    //     {" | "}
    //     <FormattedMessage
    //       id={`app.offers.date.time.${oTimeSlot.guestState.toLowerCase()}`}
    //     />
    //   </option>
    // ));

    const children = aTimeSlots.map((oTimeSlot, index) => (
      <option key={`option-${index}`} value={JSON.stringify(oTimeSlot)}>
        {oTimeSlot.timeStart.match(/\d{2}:\d{2}/g)} Uhr
        {" - "}
        {oTimeSlot.timeEnd.match(/\d{2}:\d{2}/g)} Uhr
        {" | "}
        <FormattedMessage
          id={`app.offers.date.time.${oTimeSlot.guestState.toLowerCase()}`}
        />
      </option>
    ));

    /** add id of current event to current slot from the offer of the current event */
    const curSelectedOption = { ...this.state.eventOfferSlot };
    curSelectedOption.eventId = this.state.event.id;

    return (
      <Select
        value={JSON.stringify(curSelectedOption)}
        className="form-control"
        onChange={(index) => this.updateEventByTimeSlot(index.target.value)}
      >
        {children}
      </Select>
    );
  }

  getDropdownWithEventOffers() {
    if (this.state.eventOfferList === null) return null;

    const children = [];
    const aEventOffers = this.state.eventOfferList;

    aEventOffers.forEach((eventOfferContainer) => {
      children.push(
        <option
          key={eventOfferContainer.offer.id}
          value={eventOfferContainer.offer.id}
        >
          {eventOfferContainer.offer.title}
        </option>
      );
    });

    return (
      <Select
        value={this.state.eventOffer.offer.id}
        className="form-control"
        onChange={(index) => this.updateOfferByOfferId(index.target.value)}
      >
        {children}
      </Select>
    );
  }

  /**
   * checks whether there are events
   * on the given date
   */
  getEventDayByDate(date) {
    let eventDay = null;

    this.state.eventDayList.forEach((evDay) => {
      if (isSameDay(new Date(evDay.date), date)) eventDay = evDay;
    });

    return eventDay;
  }

  /**
   * check given time against all time slots
   * in all events in all its offers, to find the related event
   */
  updateEventByTimeSlot(sSlot) {
    const oSlot = JSON.parse(sSlot);
    const sTimeStart = oSlot.timeStart;
    const sTimeEnd = oSlot.timeEnd;
    const iEventId = oSlot.eventId;
    const aEvents = this.state.eventDay.events;
    let event = null;
    let eventOfferSlot = null;

    /**
     * loop through all events of current eventDay
     * and loop on each events its offers
     * and loop on each offer its slots to
     */
    aEvents.forEach((ev) => {
      /** only loop through offers from an event that matches the id of the slot event id * */
      if (ev.id === iEventId) {
        ev.offers.forEach((offerContainer) => {
          offerContainer.slots.forEach((slot) => {
            /** only look for matching timeslots in offer that matches the id of current selected offer* */
            if (this.state.eventOffer.offer.id === offerContainer.offer.id) {
              if (slot.timeStart === sTimeStart && slot.timeEnd === sTimeEnd) {
                event = ev;
                eventOfferSlot = slot;
              }
            }
          });
        });
      }
    });

    this.updateTherapistBy(event);
    this.updateEvent(event);
    this.setState({
      eventOfferSlot,
      isReservationFormVisible: false,
    });
  }

  /**
   * updates offerList state
   */
  updateOfferListBy(offerList) {
    let eventOfferList = null;

    if (offerList.length > 1) eventOfferList = offerList;

    this.setState({ eventOfferList });
  }

  /**
   * updates all offer related states
   */
  updateOfferBy(offerContainer) {
    let offer = null;
    let eventOfferPrice = null;
    let eventOfferSubvention = null;

    if (offerContainer) offer = offerContainer.offer;

    if (offer.price) {
      eventOfferPrice = offer.price.total;
      eventOfferSubvention = offer.price.companySubventionAmount;
    } else if (offerContainer.price) {
      eventOfferPrice = offerContainer.price.total;
      eventOfferSubvention = offerContainer.price.companySubventionAmount;
    }

    this.setState({
      eventOffer: offerContainer,
      eventOfferSlot: AppointmentBooking.getInitialEventOfferSlot(
        offerContainer.slots
      ),
      eventOfferPrice,
      eventOfferSubvention,
      contactNumberValue: "",
      contactNumberRequired: offer.contactNumberRequired,
    });
  }

  static getInitialEventOfferSlot(slots) {
    const filterSlotAvailable = (slot) => slot.guestState === "AVAILABLE";
    const filterSlotNotAvailable = (slot) =>
      slot.guestState === "NOT_AVAILABLE";

    if (slots.some(filterSlotAvailable)) return slots.find(filterSlotAvailable);
    if (slots.some(filterSlotNotAvailable))
      return slots.find(filterSlotNotAvailable);
    const result = slots.find((slot) => slot.guestState === "BOOKED");
    if (!result) throw Error("No slots available");
    return result;
  }

  updateOfferByOfferId(offerId) {
    const { eventDay } = this.state;
    let offerContainer = null;

    eventDay.events.forEach((ev) => {
      ev.offers.forEach((offerCont) => {
        if (offerId === offerCont.offer.id) offerContainer = offerCont;
      });
    });

    this.updateOfferBy(offerContainer);
  }

  /**
   * updates all information of the view
   * based on given date if an related event exists
   */
  updateViewByDate = (date) => {
    /** if there is no date selected, no update is needed */
    if (!date) return;

    /** if given date is same as current, no update is needed */
    if (this.state.date === date) return;

    /** if there are no events, no update is needed */
    const eventDay = this.getEventDayByDate(date);

    if (eventDay === null) return;

    /** set eventDay */
    this.setState({ eventDay });

    let firstEvent = eventDay.events[0];

    let newOfferContainer = null;
    eventDay.events.forEach((event) => {
      event.offers.forEach((offerContainer) => {
        /** if current offerContainer exists in newly by date selected event - take it */
        if (offerContainer.offer.id === this.state.eventOffer.offer.id) {
          newOfferContainer = offerContainer;
          firstEvent = event;
        }
      });
    });

    /** if current offerContainer didn't exist take the first from new event */
    if (newOfferContainer === null) newOfferContainer = firstEvent.offers[0];

    this.updateEvent(firstEvent);
    this.updateOfferBy(newOfferContainer);
    this.updateOfferListBy(firstEvent.offers);
    this.updateTherapistBy(firstEvent);

    this.setState({ date, isReservationFormVisible: false });
  };

  disabledDays(day) {
    return !(this.state.enabledDays ?? []).some((date) => isSameDay(day, date));
  }

  initState() {
    if (this.props.eventDayList.length > 0) {
      const { eventDayList } = this.props;
      const iTherapyOfferId = this.props.therapyOfferId;

      /** contains only events that are bookable */
      const aFilteredEventDayList = eventDayList.filter(
        (evDay) =>
          !this.isDateDisabledDueToDisabledTimeSlots(
            eventDayList,
            evDay.date,
            iTherapyOfferId
          )
      );

      /** set all event days */
      this.setState({ eventDayList: aFilteredEventDayList });

      const firstEventDay = AppointmentBooking.getInitialEventDayList(
        aFilteredEventDayList
      );

      /** set all events of first eventDay */
      this.setState({ eventDay: firstEventDay });

      const firstEvent = firstEventDay.events[0];

      /** set the current offer by id of selected offer from side-menu */
      let newOfferContainer = firstEvent.offers.find(
        (item) => item.offer.id === this.props.therapyOfferId
      );
      if (!newOfferContainer) {
        newOfferContainer = firstEvent.offers[0];
      }

      this.updateEvent(firstEvent);
      this.updateOfferBy(newOfferContainer);
      this.updateOfferListBy(firstEvent.offers);
      this.updateTherapistBy(firstEvent);

      /** set date */
      const date = new Date(firstEvent.date);
      this.setState({ date });
      this.getHighlightedDates(eventDayList, firstEvent.offers[0]);

      const enabledDays = [];

      const aEventDayList = eventDayList;
      const iCurOfferId = newOfferContainer.offer.id;

      if (aEventDayList === null) return enabledDays;

      aEventDayList.forEach((event) => {
        const aDate = event.date.split("-");
        const day = aDate[2];
        const month = aDate[1] - 1;
        const year = aDate[0];

        if (
          !this.isDateDisabledDueToDisabledTimeSlots(
            aEventDayList,
            event.date,
            iCurOfferId
          )
        )
          enabledDays.push(new Date(year, month, day));
      });

      this.setState({ enabledDays });
    }
    return null;
  }

  static getInitialEventDayList(days) {
    let firstDate = null;
    days.forEach((evDay) => {
      const firstSlot = AppointmentBooking.getInitialEventOfferSlot(
        evDay.events[0].offers[0].slots
      );
      if (firstSlot.guestState === "AVAILABLE" && firstDate === null) {
        firstDate = evDay;
      }
    });

    if (firstDate !== null) return firstDate;

    if (days.length > 0) return days[0];

    throw Error("No date available");
  }

  componentDidMount() {
    this.initState();
    budgetLeftError({
      paymentMethod: this.state.paymentMethod,
      employeeBudgetLeft: this.props.employeeBudgetLeft,
      eventOfferPrice: this.state.eventOfferPrice,
      alertModal: this._alertModal,
      intl: this.props.intl,
      closeModal: this.props.closeModal,
      history: this.props.history,
      offerId: this.props.offerId,
    });
  }

  componentDidUpdate(prev) {
    if (this.props.eventDayList !== prev.eventDayList) {
      this.initState();
      this.updateViewByDate(this.state.date);
    }
    budgetLeftError({
      paymentMethod: this.state.paymentMethod,
      employeeBudgetLeft: this.props.employeeBudgetLeft,
      eventOfferPrice: this.state.eventOfferPrice,
      alertModal: this._alertModal,
      intl: this.props.intl,
      closeModal: this.props.closeModal,
      history: this.props.history,
      offerId: this.props.offerId,
    });
  }

  resetState() {
    if (this.props.reset !== undefined) {
      this.props.reset();
    }
    this.setState({
      showMessage: false,
      showLoader: false,
      bookSuccess: null,
    });
  }

  formatPrice(number) {
    return this.props.intl.formatNumber(number, {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  render() {
    if (this.state.bookSuccess) {
      if (!this.state.showMessage && this.state.voteLink !== null) {
        return (
          <Content>
            <Row>
              <Col xs={12}>
                <Questionnaire
                  survey={this.state.survey}
                  code={this.state.surveyCode}
                />
              </Col>
            </Row>
          </Content>
        );
      }

      return (
        <Content>
          <Row>
            <Col xs={12}>
              <VerticallyAlignedMiddle height={window.innerHeight / 2}>
                <strong>
                  <span className="large">
                    <FormattedMessage id="app.offers.bookSuccess" />
                  </span>
                </strong>
              </VerticallyAlignedMiddle>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="d-grid">
                <Button
                  onClick={() => {
                    this.resetState();
                  }}
                  variant="primary"
                  size="lg"
                >
                  <FormattedMessage id="app.offers.bookAnother" />
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
      );
    }

    const modifiersStyles = {
      booked: {
        backgroundColor: theme.events.booked.background,
        color: "white",
      },
      reserved: {
        backgroundColor: theme.events.reserved.background,
        color: "white",
      },
      available: {
        backgroundColor: theme.events.available.background,
        color: "white",
      },
      lowAvailable: {
        backgroundColor: theme.events.low_available.background,
        color: "white",
      },
      notAvailable: {
        backgroundColor: theme.events.not_available.background,
        color: "white",
      },
    };

    return (
      <Content>
        <AlertModalBox
          title="error"
          text="error"
          ref={(modal) => {
            this._alertModal = modal;
          }}
        />
        <ModalBox
          title={this.props.intl.formatMessage({
            id: "app.offers.date.reserve",
          })}
          text={this.props.intl.formatMessage({
            id: "app.offers.date.reserve.text",
          })}
          ref={(modal) => {
            this._modalBox = modal;
          }}
        />
        {this.props.title && (
          <Row>
            <Col xs={12}>
              <TitleOffer>{this.props.title}</TitleOffer>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <ImgWrapper>
              {this.state.therapistPhoto && (
                <img
                  src={JSON.parse(this.state.therapistPhoto)}
                  alt={this.state.therapistName}
                />
              )}
            </ImgWrapper>
            <Title>
              <FormattedMessage id="app.appointments.therapist" />
            </Title>
            <span className="text-muted">
              {this.state.therapistName ? this.state.therapistName : <br />}
            </span>
            <Title>
              <FormattedMessage id="app.appointments.roomInfo" />
            </Title>
            <span className="text-muted">
              {this.state.eventRoomName ? this.state.eventRoomName : <br />}
            </span>
          </Col>
        </Row>
        <br />{" "}
        {this.state.isReservationFormVisible ? (
          <Form onSubmit={(event) => this.submitReservation(event)}>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.offers.date.date" />
                    </Title>
                  </FormLabel>
                  <DayPickerInput
                    value={this.state.date}
                    onDayChange={this.updateViewByDate}
                    modifiers={this.getHighlightedDates()}
                    modifiersStyles={modifiersStyles}
                    disabledDays={this.disabledDays}
                    locale={this.props.intl.locale}
                  />{" "}
                  {this.isCurrentTimeSlotAvailableOnOtherDates() && (
                    <p className="form-text">
                      <FormattedMessage id="app.offers.date.time.info" />
                    </p>
                  )}
                  <p className="form-text">
                    <FormattedMessage id="app.offers.date.book.info" />
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.offers.date.time" />
                    </Title>
                  </FormLabel>
                  {this.state.eventOffer ? (
                    this.getDropdownWithTimeSlots()
                  ) : (
                    <select className="form-control">
                      <option value="no-slots-available">
                        <FormattedMessage id="app.appointments.editAppointment.noTimeSlots">
                          {(txt) => txt}
                        </FormattedMessage>
                      </option>
                    </select>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <hr />
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <input
                        type="radio"
                        checked={!this.state.isBinding}
                        onChange={() => this.setState({ isBinding: false })}
                      />
                      <OptionTextWrapper>
                        <FormattedMessage id="app.offers.date.reserve.uncommitted" />
                      </OptionTextWrapper>
                    </Title>
                  </FormLabel>
                </FormGroup>
                <hr />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <input
                        type="radio"
                        checked={this.state.isBinding}
                        onChange={() =>
                          this.setState({
                            isBinding: true,
                            binding: this.bindings[0],
                          })
                        }
                      />
                      <OptionTextWrapper>
                        <FormattedMessage id="app.offers.date.reserve.committed" />
                      </OptionTextWrapper>
                    </Title>
                  </FormLabel>
                </FormGroup>
                <FormGroup>
                  {this.state.isBinding && this.getDropdownWithBindings()}
                </FormGroup>
                <hr />
              </Col>
            </Row>
            <div className="d-grid">
              <Button type="submit" variant="primary" size="lg">
                <FormattedMessage id="app.offers.date.reserve" />
              </Button>
            </div>
          </Form>
        ) : (
          <Form
            onSubmit={(event) => this.submitForm(event)}
            noValidate
            validated={this.state.validated}
          >
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.offers.date.date" />
                    </Title>
                  </FormLabel>
                  <DayPickerInput
                    value={this.state.date}
                    onDayChange={this.updateViewByDate}
                    modifiers={this.getHighlightedDates()}
                    modifiersStyles={modifiersStyles}
                    disabledDays={this.disabledDays}
                    locale={this.props.intl.locale}
                    defaultMonth={this.state.date}
                  />{" "}
                  {this.isCurrentTimeSlotAvailableOnOtherDates() && (
                    <p className="form-text">
                      <FormattedMessage id="app.offers.date.time.info" />
                    </p>
                  )}
                  <p className="form-text">
                    <FormattedMessage id="app.offers.date.book.info" />
                  </p>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.offers.date.time" />:
                    </Title>
                  </FormLabel>
                  {this.state.eventOffer ? (
                    this.getDropdownWithTimeSlots()
                  ) : (
                    <select className="form-control">
                      <option value="no-slots-available">
                        <FormattedMessage id="app.appointments.editAppointment.noTimeSlots">
                          {(txt) => txt}
                        </FormattedMessage>
                      </option>
                    </select>
                  )}

                  {this.state.eventOfferSlot?.guestState === "BOOKED" && (
                    <p className="form-text">
                      <FormattedMessage id="app.calendar.help.booked" />
                    </p>
                  )}
                  <p className="form-text"> </p>
                </FormGroup>
              </Col>
            </Row>
            {this.state.eventOfferList && (
              <Row>
                <Col xs={12} className="mb-3">
                  <FormGroup>
                    <FormLabel>
                      <Title>
                        <FormattedMessage id="app.offers.title" />:
                      </Title>
                    </FormLabel>
                    {this.getDropdownWithEventOffers()}
                  </FormGroup>
                </Col>
              </Row>
            )}
            {/* <Row>
              <Col xs={12} className="mb-3">
                <FormGroup>
                  <FormLabel>
                    <Title>
                      <FormattedMessage id="app.offers.info" />:
                    </Title>
                  </FormLabel>
                  <CustomTextarea
                    className="form-control"
                    value={this.state.textAreaValue}
                    onChange={(event) => this.updateTextAreaValue(event)}
                    rows={4}
                  />
                </FormGroup>
              </Col>
            </Row> */}

            {this.state.contactNumberRequired && (
              <Row>
                <Col xs={12} className="mb-3">
                  <FormGroup controlId="phonenumber">
                    <FormLabel>
                      <Title>
                        <FormattedMessage id="app.offers.phone" />:
                      </Title>
                    </FormLabel>
                    <CustomInput
                      className="form-control"
                      value={this.state.contactNumberValue}
                      onChange={(event) => this.updateContactNumberValue(event)}
                      required
                      maxlength={20}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FormattedMessage id="app.offers.phoneRequired" />
                    </Form.Control.Feedback>
                  </FormGroup>
                </Col>
              </Row>
            )}

            {this.props.config.showPrices && (
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <FormLabel>
                      <Title>
                        <FormattedMessage id="app.offers.price.price" />:
                      </Title>
                    </FormLabel>
                    <p>
                      {this.formatPrice(this.state.eventOfferPrice)}
                      <FormattedMessage id="app.global.priceUnit" />
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {this.props.config.showPrices ||
              (this.state.eventOfferPrice > 0 && (
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <FormLabel>
                        <Title>
                          <FormattedMessage id="app.appointments.paymentMethod" />
                          :
                        </Title>
                      </FormLabel>
                      <PaymentSelect
                        eventOffer={this.state.eventOffer}
                        refreshHandler={(handler) => {
                          this._paymentSelectRefresh = handler;
                        }}
                        value={this.state.paymentMethod}
                        onReady={this.initPaymentMethod}
                        onChange={(event) =>
                          this.updatePaymentMethodBy(event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ))}
            {this.props.couponsEnabled &&
              this.state.paymentMethod !== "CREDIT" &&
              (this.props.config.showPrices ||
                this.state.eventOfferPrice > 0) && (
                <Row>
                  <Col xs={12}>
                    <InvokeCoupon
                      onDone={() => {
                        this._paymentSelectRefresh();
                        this.setState({ paymentMethod: "CREDIT" });
                      }}
                    />
                  </Col>
                </Row>
              )}
            <ButtonContent>
              <div className="d-grid">
                <Button
                  processing={this.state.showLoader}
                  type="submit"
                  variant="primary"
                  size="lg"
                >
                  <FormattedMessage
                    id={`app.offers.date.${
                      this.state.eventOfferSlot?.guestState === "BOOKED"
                        ? "edit"
                        : "book"
                    }`}
                  />
                </Button>
              </div>
            </ButtonContent>
          </Form>
        )}
      </Content>
    );
  }
}

// pragma mark - compose mutations

const AppointmentBookingMutations = compose(
  graphql(BOOK_DATE_MUTATION, { name: "bookDate" }),
  graphql(RESERVATION_MUTATION, { name: "reservation" }),
  graphql(VOTE_MUTATION, { name: "vote" }),
  graphql(CALENDAR_COMPONENT_QUERY, {
    options: (ownProps) => ({
      variables: { week: base64.encode((ownProps.week - 1).toString()) },
    }),
    skip: (props) => !props.week,
  })
)(AppointmentBooking);

// pragma mark - export component

export const AppointmentBookingForm = injectIntl(AppointmentBookingMutations);
