import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { Row, Col, FormText, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Button from "../CustomBootstrap/Button";
import Question from "./Question";

const GroupForm = styled("form")`
  font-size: 0.9em;
  h1 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.5em;
  }
  margin-top: 30px;

  Question {
    padding-bottom: 20px;
  }
`;

// eslint-disable-next-line
const GroupHelp = styled(FormText)`
  display: block;
  padding-bottom: 20px;
`;

class QuestionnaireGroup extends Component {
  constructor(props) {
    super(props);

    this.sendAnswers = this.sendAnswers.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);

    const questions =
      props.questionGroup === null
        ? null
        : QuestionnaireGroup.setupQuestions(props.questionGroup);
    this.state = {
      complete: QuestionnaireGroup.isComplete(questions),
      questionGroup: this.props.questionGroup,
      questions,
    };
  }

  sendAnswers() {
    const answers = [];
    this.state.questions.map((question) => {
      if (Array.isArray(question.answer)) {
        question.answer.map((answer) => {
          answers.push({
            answer,
            questionId: question.id,
          });
          return answer;
        });
      } else if (question.answer instanceof Object) {
        Object.keys(question.answer).map((key) => {
          answers.push({
            answer: question.answer[key],
            key,
            questionId: question.id,
          });
          return key;
        });
      } else {
        answers.push({
          answer: question.answer,
          questionId: question.id,
        });
      }
      return question;
    });

    this.props.onComplete({
      answers,
      questionGroupId: this.state.questionGroup.id,
    });
  }

  componentDidUpdate(prev) {
    if (
      prev.questionGroup === null ||
      this.props.questionGroup.id !== this.state.questionGroup.id
    ) {
      const questions = QuestionnaireGroup.setupQuestions(
        this.props.questionGroup
      );
      this.setState({
        questionGroup: this.props.questionGroup,
        questions,
        complete: QuestionnaireGroup.isComplete(questions),
      });
    }
  }

  static isComplete(questions) {
    let valid = true;
    questions.map((question) => {
      if (question.valid === false) {
        valid = false;
      }
      return question;
    });
    return valid;
  }

  updateQuestion(questionId, answer, valid) {
    const { questions } = this.state;
    questions[questionId].valid = valid;
    questions[questionId].answer = answer;
    this.setState({
      questions,
      complete: QuestionnaireGroup.isComplete(questions),
    });
  }

  static setupQuestions(questionGroup) {
    const questions = [];
    questionGroup.questions.map((question) => {
      const questionObj = { display: null, ...question };
      const displayOptions = {};
      question.questionTypeOptions.map((option) => {
        displayOptions[option.questionnaireQuestionTypeOption.param] =
          option.value;
        return option;
      });
      questionObj.display = {
        type: parseInt(question.questionType.id, 10),
        options: displayOptions,
        answers: question.answers,
      };
      delete questionObj.answers;
      delete questionObj.questionType;
      delete questionObj.questionTypeOptions;
      questionObj.valid = question.requiredAnswers === 0;
      questions[question.id] = questionObj;
      return question;
    });
    return questions;
  }

  render() {
    if (
      this.state.questionGroup === undefined ||
      this.state.questionGroup === null
    ) {
      return null;
    }

    return (
      <GroupForm
        onSubmit={(event) => {
          this.validateAnswers();
          event.preventDefault();
          return false;
        }}
      >
        <Row>
          <Col xs={12} md={12}>
            <h1>{this.state.questionGroup.title}</h1>
            {React.createElement(GroupHelp, {
              dangerouslySetInnerHTML: {
                __html: this.state.questionGroup.text,
              },
            })}
          </Col>
        </Row>
        {this.state.questions.map((question) => (
          <Question
            key={question.id}
            question={question}
            onUpdate={this.updateQuestion}
          />
        ))}
        <Row>
          <Col xs={12} md={12}>
            {this.state.complete === false && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    {this.state.complete === false
                      ? this.props.intl.formatMessage({
                          id: "app.survey.questionnaire.tooltip.incomplete",
                        })
                      : ""}
                  </Tooltip>
                }
              >
                <div
                  style={{
                    display: "inline-block",
                    cursor: "not-allowed",
                    float: "right",
                    marginTop: "50px",
                  }}
                >
                  <Button
                    onClick={() => {
                      this.sendAnswers();
                    }}
                    variant="primary"
                    size="lg"
                    disabled
                    style={{
                      pointerEvents: "none",
                      margin: "0px",
                    }}
                  >
                    <FormattedMessage id="app.survey.questionnaire.next" />
                  </Button>
                </div>
              </OverlayTrigger>
            )}

            {this.state.complete === true && (
              <div
                style={{
                  display: "inline-block",
                  cursor: "not-allowed",
                  float: "right",
                  marginTop: "50px",
                }}
              >
                <Button
                  onClick={() => {
                    this.sendAnswers();
                  }}
                  variant="primary"
                  size="lg"
                  style={{
                    pointerEvents: "auto",
                    margin: "0px",
                  }}
                >
                  <FormattedMessage id="app.survey.questionnaire.next" />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </GroupForm>
    );
  }
}

export default injectIntl(QuestionnaireGroup);
